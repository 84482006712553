import type { Result } from '@coveo/headless';
import { Locale } from '@customer-portal/constants';

export const SEARCH_SOURCE: string = 'cp';
export const SEARCH_HUB_NAME: string = 'CustomerPortal-Main';
export const DEFAULT_SUGGESTIONS_COUNT: number = 3;
export const DEFAULT_RESULTS_COUNT: number = 10;
export const SEARCH_MINIMUM_CHARACTER_LENGTH: number = 3;
export const SEARCH_MAXIMUM_CHARACTER_LENGTH: number = 4000;
export const DEFAULT_FACET_VALUES_COUNT: number = 5;
export const SEARCH_SUGGESTIONS_DELAY_MS: number = 500;
export const QUERY_TEXT_URL_KEY = 'q';
export const DOCS_COMBINED_VERSION_NAME = 'Cloud';
export const DOCS_VERSION_SKIP_TAG_NAME = 'SKIP';

export enum SearchResultSource {
  TECHNICAL_SOLUTIONS = 'Technical Solution Articles',
  ACADEMY = 'UiPath Academy',
  DOCS = 'UiPath Documentation Portal',
  FORUMS = 'UiPath Forums',
  KNOWLEDGE_BASE = 'UiPath Knowledge Base',
  YOUTUBE = 'UiPath Youtube',
}

export const SearchResultSourceLabelMap: Record<string, { localText: string; fallbackText: string }> = {
  [SearchResultSource.TECHNICAL_SOLUTIONS]: {
    localText: 'search_content_type_technical_solutions_label',
    fallbackText: 'Technical solution articles',
  },
  [SearchResultSource.DOCS]: {
    localText: 'search_content_type_docs_label',
    fallbackText: 'Product documentation',
  },
  [SearchResultSource.FORUMS]: {
    localText: 'search_content_type_forums_label',
    fallbackText: 'User communities',
  },
  [SearchResultSource.KNOWLEDGE_BASE]: {
    localText: 'search_content_type_knowledge_base_label',
    fallbackText: 'Knowledge base',
  },
  [SearchResultSource.YOUTUBE]: {
    localText: 'search_content_type_youtube_label',
    fallbackText: 'Videos',
  },
};

export enum SearchAnalyticsActions {
  DOCUMENT_OPEN = 'DOCUMENT_OPEN',
}

export interface AnalyticsActionsPayload {
  [SearchAnalyticsActions.DOCUMENT_OPEN]: Result;
}

/**
 * Maps user interface locales to supported search locales.
 * This mapping ensures that locales without dedicated search support fall back to appropriate supported locales.
 *
 * Used in conjunction with getSupportedSearchLanguage() to determine the search interface language.
 */
export const LOCALE_TO_SUPPORTED_SEARCH_LOCALE_MAP: Record<Locale, Locale> = {
  [ Locale.en ]: Locale.en,
  [ Locale.de ]: Locale.de,
  [ Locale.es ]: Locale.es,
  [ Locale.fr ]: Locale.fr,
  [ Locale.pt ]: Locale.pt,
  [ Locale.ja ]: Locale.ja,
  [ Locale['zh-CN'] ]: Locale['zh-CN'],

  // get relevant results even in a  similar localized variation as exact locale is not supported for  Español (México) & Português (Brasil)
  [ Locale['es-MX'] ]: Locale.es,  //  Español (México) -> Español
  [ Locale['pt-BR'] ]: Locale.pt, // Português (Brasil) -> Português

  // When a user selects a language that is not fully supported for translations,
  // fallback to English to avoid showing an 0 search results page
  [ Locale.tr ]: Locale.en,       // Turkish falls back to English
  [ Locale.ko ]: Locale.en,       // Korean falls back to English
  [ Locale.ru ]: Locale.en,       // Russian falls back to English
  [ Locale['zh-TW'] ]: Locale.en, // Taiwan falls back to English

};

import {
  CaseRecordType,
  CompanyAccountType,
  SUPPORTED_BASIC_CASE_TYPES,
} from '@customer-portal/constants';

export const KBVisibilityControls = {
  all_accounts: 'All Accounts',
  success_tier: 'Success Tier Accounts',
  activate: 'Success Tier - Activate Only',
};

export const isBasicCaseType = (recordTypeId?: CaseRecordType) => recordTypeId && SUPPORTED_BASIC_CASE_TYPES.includes(recordTypeId);
export const isPremiumCaseType = (recordTypeId?: CaseRecordType) => recordTypeId === CaseRecordType.PREMIUM;

export const AllowedHapoAccounts = [
  '0011Q00002BkScFQAV',
  '00136000016Ts3vAAC',
  '0011Q00002HCj6oQAD',
];

export const PREMIUM_ACCOUNT_TYPE_TO_MAIN_MENU_TITLE: Partial<Record<CompanyAccountType, {
  textKey: string;
  fallbackText: string;
}>> = {
  [CompanyAccountType.PREMIUM]: {
    textKey: 'header_menu_premium_account_premium',
    fallbackText: 'Premium',
  },
  [CompanyAccountType.PREMIUM_PLUS]: {
    textKey: 'header_menu_premium_account_premium_plus',
    fallbackText: 'Premium Plus',
  },
  [CompanyAccountType.PREMIUM_SUPPORT]: {
    textKey: 'header_menu_premium_account_premium_support',
    fallbackText: 'Premium',
  },
  [CompanyAccountType.ACTIVATE]: {
    textKey: 'header_menu_premium_account_activate_success',
    fallbackText: 'Activate Success',
  },
  [CompanyAccountType.ENTERPRISE]: {
    textKey: 'header_menu_premium_account_enterprise_success',
    fallbackText: 'Enterprise Success',
  },
  [CompanyAccountType.GOLD_TIER]: {
    textKey: 'header_menu_premium_account_gold_tier_support',
    fallbackText: 'Gold Support',
  },
  [CompanyAccountType.PLATINUM_TIER]: {
    textKey: 'header_menu_premium_account_platinum_tier_support',
    fallbackText: 'Platinum Support',
  },
};

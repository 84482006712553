import {
  FileSizes,
  Header,
  OnPremWhiteListedFileExtensions,
} from '@customer-portal/constants';
import axios from 'axios';
import React, {
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { Helmet } from 'react-helmet';
// Translations
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

// styles
import * as styles from '../../assets/css/Collab/index';
import * as heroStyles from '../../assets/css/CustomerPortalBasicHero';
// Images
import CloudUp from '../../assets/img/svg/collab/Cloud_Up_Fill.svg';
// Google Analytics
import CustomerPortalLoader from '../../components/CustomerPortal-Loader';
// Components
import Container from '../../components/CustomerPortal-New-Container';
import NoCompanyId from '../../components/CustomerPortal-NoCompanyId';
import CustomerPortalPagination from '../../components/CustomerPortal-Pagination';
import CustomerPortalOnPremDoc from '../../components/onPremDataExport/OnPrem-Doc';
import { ONPREM_ASSETS_URL } from '../../constants/network.constants';
// Constants
import { OnPremActions } from '../../constants/onprem.constants';
import { useAuth } from '../../contexts/auth';
// Interfaces
import type { IOnPremPageState } from '../../interfaces/onprem.interface';
// Utils
import downloadDocument from '../../lib/customerPortalDownload.utils';
import { isFileAttachmentSupported } from '../../lib/file.utils';
import { searchReducer } from '../../reducers/onprem_reducers';
import { StoreContext } from '../../store';

/* This is the page component for Knowledge Base.
    Component consumed in route level
    Renders all the layout for all things knowledge base
  */
const CustomerPortalOnPremPage = (props: any) => {
  const { t } = useTranslation('common');
  /* Default constants */
  const fileInput = React.createRef<any>();
  const docsPerPage = 10;
  const { fileId: folderId } = useParams<any>();

  /* State */
  const initialPageState: IOnPremPageState = {
    keyword: '',
    isCompleteSearching: false,
    documents: [],
    currentPage: 1,
    totalDocs: 0,
    sortBy: 'Date',
    sortDirection: -1,
    skipResult: 0,
    folderViewId: folderId || '',
    folderViewName: '',
  };
  const [ folderNotFound, setFolderNotFound ] = useState(false);
  const [ acceptedFiles, setAcceptedFiles ] = useState<File[]>([]);
  const [ uploadedFiles, setUploadedFiles ] = useState<any[]>([]);
  const [ failedUploadFiles, setFailedUploadFiles ] = useState<File[]>([]);
  const [ isUploading, setIsUploading ] = useState(false);

  // Common dispatchContext for collab, onprem, kb-document etc.
  const {
    state, dispatch: dispatchContext,
  } = useContext(StoreContext);
  const {
    authType, accessToken,
  } = useAuth();
  const [ searchState, dispatchStateChangeAction ] = useReducer(
    searchReducer,
    initialPageState
  );

  /* Effects */
  useEffect(() => {
    // Function fetches the documents when the page is loaded
    const fetchDocuments = async () => {
      try {
        const result = await axios.get(
          `${
            folderId
              ? `${ONPREM_ASSETS_URL}/${folderId}`
              : `${ONPREM_ASSETS_URL}`
          }`,
          {
            headers: {
              [Header.AUTHORIZATION]: `Bearer ${accessToken}`,
              [Header.SELECTED_ACCOUNT_ID]: state.companyId,
              [Header.AUTH_TYPE]: authType,
            },
            params: {
              company_id: state.companyId,
              sort_by: initialPageState.sortBy,
              sort_direction: initialPageState.sortDirection,
              top: docsPerPage,
              skip: initialPageState.skipResult,
            },
          }
        );

        if (result.data.data && result.data.statusCode === 200) {
          // calls searchReducer to update the state from onprem_reducers.tsx which takes in current state and action and returns new state
          dispatchStateChangeAction({
            type: OnPremActions.FETCH_SUCCESS,
            payload: {
              documents: result.data.data.docs,
              totalDocs: result.data.data.total_count,
            },
          });
        }
      } catch (e) {
        console.log(e);
      }
    };

    if (state.companyId !== '') {
      dispatchStateChangeAction({ type: OnPremActions.FETCH_INIT });

      fetchDocuments();
    }
  }, [ props.location.pathname ]); // pathname: /self-hosted-data

  /* Helper function to get the created_by object when current user creates/uploads asset */
  const getCreatedByFromState = () => {
    const created_by: { name: string; picture?: string; picture_storage_account_name?: string } = { name: state.userName };
    if (state.profilePhoto?.includes('/')) {
      const index = state.profilePhoto.indexOf('/');
      created_by.picture_storage_account_name = state.profilePhoto.substring(0, index);
      created_by.picture = state.profilePhoto.substring(index + 1);
    }
    return created_by;
  };

  /* Event Handlers */
  const handleUploadButtonClick = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  // Upload file to On-Prem space
  const handleFileUploadInputChange = async (e: any) => {
    if (!e) {
      return;
    }
    e.preventDefault();

    const files = Array.from(e.target.files) as File[];

    // Validate files
    const validFiles: File[] = [];
    const invalidFilesSize: string[] = [];
    const invalidFilesExtensions: string[] = [];

    files.forEach(file => {
      if (file.size > FileSizes.OneGigabyte) {
        invalidFilesSize.push(`${file.name}`);
      } else if (!isFileAttachmentSupported(file, OnPremWhiteListedFileExtensions)) {
        invalidFilesExtensions.push(`${file.name}`);
      } else {
        validFiles.push(file);
      }
    });

    if (invalidFilesSize.length > 0 || invalidFilesExtensions.length > 0) {
      dispatchContext({
        type: 'setBannerType',
        payload: 'error',
      });

      let errorMessage = '';

      if (invalidFilesSize.length > 0) {
        errorMessage += t(
          'onprem_space_file_size_error',
          'File exceeds 1 GB: {{files}}',
          { files: invalidFilesSize.join(', ') }
        );
      }

      if (invalidFilesExtensions.length > 0) {
        if (errorMessage) {
          errorMessage += '\n';
        }
        errorMessage += t(
          'onprem_space_file_type_error',
          'Invalid file type: {{files}}',
          { files: invalidFilesExtensions.join(', ') }
        );
      }

      dispatchContext({
        type: 'setBannerMsg',
        payload: errorMessage,
      });
      return;
    }

    setAcceptedFiles([ ...acceptedFiles, ...validFiles ]);
    setIsUploading(true);

    // Upload each file
    for (const file of validFiles) {
      const formData = new FormData();
      formData.set('file', file);
      formData.set('company_id', state.companyId);
      if (folderId) {
        formData.set('folder_id', folderId);
      }

      try {
        dispatchContext({
          type: 'setBannerType',
          payload: 'info',
        });
        dispatchContext({
          type: 'setBannerMsg',
          payload: t(
            'onprem_space_please_wait_info_msg',
            'File is uploading, it might take a while, you will receive notification when it’s uploaded. Please don\'t leave the page until the upload is finished.'
          ),
        });

        dispatchContext({
          type: 'setBannerAutoHide',
          payload: false,
        });

        const result = await axios.post(`${ONPREM_ASSETS_URL}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            [Header.AUTHORIZATION]: `Bearer ${accessToken}`,
            [Header.SELECTED_ACCOUNT_ID]: state.companyId,
            [Header.AUTH_TYPE]: authType,
          },
        });

        if (result.status === 201 && result.data.data) {
          const uploadedFile = {
            _id: result.data.data.id,
            slug: result.data.data.slug,
            display_name: result.data.data.display_name,
            mime_type: result.data.data.mime_type,
            is_folder: false,
            created_on: result.data.data.created_on,
            created_by_uipath: state.isUiPath,
            created_by_name: state.userName,
            created_by: getCreatedByFromState(),
          };

          setUploadedFiles(prev => [ ...prev, uploadedFile ]);
          dispatchStateChangeAction({
            type: OnPremActions.ASSET_UPLOADED,
            payload: {
              docsPerPage,
              ...uploadedFile,
            },
          });
        }
      } catch (error) {
        console.error(`Failed to upload ${file.name}:`, error);
        setFailedUploadFiles(prev => [ ...prev, file ]);
      }
    }

    setIsUploading(false);

    // Show success/error message
    if (failedUploadFiles.length === 0) {
      dispatchContext({
        type: 'setBannerType',
        payload: 'success',
      });
      dispatchContext({
        type: 'setBannerMsg',
        payload: t(
          'onprem_space_upload_success',
          'All files uploaded successfully'
        ),
      });
    } else {
      dispatchContext({
        type: 'setBannerType',
        payload: 'error',
      });
      dispatchContext({
        type: 'setBannerMsg',
        payload: t(
          'onprem_space_upload_partial_success',
          'Some files failed to upload. Please try again.'
        ),
      });
    }
  };

  // When folder is clicked, this function runs and downloads the file
  // disabled from 'CustomerPortalOnPremDoc' component above.
  const handleFileClick = async (
    isFolder: boolean,
    fileId: string,
    slug: string
  ) => {
    // Download
    try {

      // Download the file
      await downloadDocument(
        `${ONPREM_ASSETS_URL}/${fileId}`,
        { company_id: state.companyId },
        {
          headers: {
            [Header.AUTHORIZATION]: `Bearer ${accessToken}`,
            [Header.SELECTED_ACCOUNT_ID]: state.companyId,
          },
        }
      );
    } catch (e) {
      console.log(e.toString());
      const errorMessage =
            e.response?.data?.data
              ? `${t(
                'onprem_space_file_download_error_msg',
                'Error downloading request:'
              )} ${e.response.data.data}`
              : `${t(
                'onprem_space_file_download_error_msg',
                'Error downloading request:'
              )} ${e.toString()}`;
      dispatchContext({
        type: 'setBannerType',
        payload: 'error',
      });
      dispatchContext({
        type: 'setBannerMsg',
        payload: errorMessage,
      });
    }

  };

  // For handling pagination
  const handlePaginationClick = async (val: number) => {
    const newPageNum = val > 1 ? val - 1 : 0;
    const url = ONPREM_ASSETS_URL;

    try {
      const result = await axios.get(url, {
        params: {
          company_id: state.companyId,
          sort_by: initialPageState.sortBy,
          sort_direction: initialPageState.sortDirection,
          top: docsPerPage,
          skip: docsPerPage * newPageNum,
        },
        headers: {
          [Header.AUTHORIZATION]: `Bearer ${accessToken}`,
          [Header.SELECTED_ACCOUNT_ID]: state.companyId,
          [Header.AUTH_TYPE]: authType,
        },
      });

      dispatchStateChangeAction({
        type: OnPremActions.PAGE_CLICKED,
        payload: {
          pageNumber: val,
          skipResult: docsPerPage * newPageNum,
        },
      });

      if (result.data.data && result.data.statusCode === 200) {
        dispatchStateChangeAction({
          type: OnPremActions.FETCH_SUCCESS,
          payload: {
            documents: result.data.data.docs,
            totalDocs: result.data.data.total_count,
          },
        });
      }
    } catch (e) {
      dispatchContext({
        type: 'setBannerType',
        payload: 'error',
      });
      dispatchContext({
        type: 'setBannerMsg',
        payload: `${t(
          'onprem_space_retriving_page_error_msg',
          'Error retrieving page:'
        )} ${val}`,
      });
    }
  };

  /* Dynamic generate display */
  const generateHero = () => (
    <heroStyles.Hero>
      <Container cssClass="CustomerPortalClientsSelection__heroContent">
        <heroStyles.Title>Self-Hosted Data</heroStyles.Title>
        <heroStyles.Subtitle>
          By providing data about your installation, UiPath will use this data solely for internal reporting to foster
          customer obsession and, ultimately, provide you with better support and experiences by enabling our teams to
          have a more complete understanding of your environment.
          <br />
          <br />
          See documentation on how to export self-hosted data. Once exported, please upload the file below. UiPath will
          securely retain your data to enhance your experience and provide seamless support whenever needed. Your
          information remains protected under our strict security and privacy
          {' '}
          <a href="https://www.uipath.com/legal/trust-and-security">policy</a>
          {' '}
.
        </heroStyles.Subtitle>
      </Container>
    </heroStyles.Hero>
  );

  const generateResultsTextHeading = () => {
    if (folderId) {
      return (
        <h2 className="CustomerPortalCollabResults__Header">
          {searchState.folderViewName}
        </h2>
      );
    }

    return (
      <h2 className="CustomerPortalCollabResults__Header">
        {t('onprem_space_body_title', 'Browse all documents')}
      </h2>
    );
  };

  const generateUploadContainerClassName = () => {
    const className = 'CustomerPortalCollab__Upload-Container';
    return className;
  };

  /* Return if no company id selected */
  if (state.companyId === '') {
    return (
      <>
        <Helmet>
          <title>
            {t(
              'onprem_space_page_title',
              'onprem Space | Customer Portal'
            )}
          </title>
        </Helmet>
        <NoCompanyId />
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>
          {t(
            'onprem_space_page_title',
            'onprem Space | Customer Portal'
          )}
        </title>
      </Helmet>

      <styles.PageWrapper>
        {generateHero()}

        <Container cssClass={generateUploadContainerClassName()}>
          <styles.UploadContainerInner
            className="CustomerPortalCollab__Upload-Container--Inner"
            onClick={handleUploadButtonClick}
          >
            <img
              className="CustomerPortalCollab__Upload"
              src={CloudUp}
              alt="Upload"
              data-testid="uploadDocButton"
            />
          </styles.UploadContainerInner>
          <form className="CustomerPortalUploadForm">
            <input
              className="CustomerPortalUploadForm__Input"
              name="fileInput"
              type="file"
              multiple
              onChange={handleFileUploadInputChange}
              ref={fileInput}
            />
          </form>
        </Container>

        {!searchState.isCompleteSearching && (
          <Container cssClass="CustomerPortalCollabResults">
            <CustomerPortalLoader />
          </Container>
        )}

        {searchState.isCompleteSearching && (
          <Container
            cssClass="CustomerPortalCollabResults"
            dataTestId="CollabSearchCompletedContainer"
          >
            <styles.CollabResultsHeader>
              {generateResultsTextHeading()}

            </styles.CollabResultsHeader>

            <div className="CustomerPortalCollabResults__Table-Header">
              <span className="CustomerPortalCollabResults__Table-Label">
                {t('onprem_space_table_row_name', 'Name')}
              </span>
              <span className="CustomerPortalCollabResults__Table-Label">
                {t('onprem_space_table_row_author', 'Author')}
              </span>
              <span className="CustomerPortalCollabResults__Table-Label">
                {t('onprem_space_table_row_date', 'Date')}
              </span>
            </div>
            {searchState.documents.map((docObj: any) => {
              const userHasAvatar =
                  docObj.created_by?.picture &&
                  docObj.created_by?.picture_storage_account_name;
              let profilePhoto;
              if (userHasAvatar) {
                profilePhoto = `${docObj.created_by.picture_storage_account_name}/${docObj.created_by.picture}`;
              }
              return (
                <CustomerPortalOnPremDoc
                  key={docObj._id}
                  fileId={docObj._id}
                  isFolder={docObj.is_folder}
                  mimeType={docObj.mime_type}
                  fileName={docObj.display_name}
                  createdByUiPath={docObj.created_by_uipath}
                  authorName={
                    docObj.created_by_uipath ? 'UiPath' : (docObj.created_by?.name || docObj.created_by_name)
                  }
                  authorAvatar={profilePhoto}
                  slug={docObj.slug}
                  dateString={docObj.created_on}

                  // Download the file functionality. is not Enabled yet
                  // handleFileClick={handleFileClick}
                />
              );
            })}
            <CustomerPortalPagination
              className="CustomerPortalPagination"
              activePage={searchState.currentPage}
              numResults={searchState.totalDocs}
              numResultsPerPage={docsPerPage}
              handleClick={handlePaginationClick}
              numPagesBeforeEllipses={5}
            />
          </Container>
        )}
      </styles.PageWrapper>
    </>
  ); // End of return
};

export default CustomerPortalOnPremPage;

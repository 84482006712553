import {
  buildResultsPerPage,
  buildUrlManager,
  loadSearchAnalyticsActions,
} from '@coveo/headless';
import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ContentWrapper } from '../../assets/css/CustomerPortalContentWrapper';
import { DEFAULT_RESULTS_COUNT } from '../../constants/search.constants';
import { useAuth } from '../../contexts/auth';
import { StoreContext } from '../../store';
import { featureFlag } from '../../utils/featureFlag';
import {
  headlessSearchEngine,
  pushNewHashToUrl,
} from '../../utils/search';
import DocsGptSection from './DocsGpt/DocsGptSection';
import FacetsSection from './Facets/FacetsSection';
import PaginationSection from './Pagination/PaginationSection';
import ResultsSection from './Results/ResultsSection';
import SearchBarSection from './SearchBar/SearchBarSection';

export const WhiteBackgroundBody = styled.div`
  background-color: ${p => p.theme.palette.semantic.colorBackground};
`;

export const BodySection = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: auto;
  max-width: 1080px;
  ${ContentWrapper}
`;

export const Main = styled.div`
  flex: 6.5;
  padding-right: ${p => p.theme.spacing(5)}px;
`;

export const Side = styled.div`
  flex: 3.5;
`;

const CustomerPortalPageSearch = () => {
  const {
    t, i18n,
  } = useTranslation('common');
  const { state } = useContext(StoreContext);
  const { accessToken } = useAuth();
  const [ isDocsAISearchEnabled, setIsDocsAISearchEnabled ] = useState(false);
  const [ isDocsAIRegenerateResponseEnabled, setIsDocsAIRegenerateResponseEnabled ] = useState(false);
  const [ isOutofScopeLanguage, setIsOutofScopeLanguage ] = useState(false);

  const buildSearchParameterManagerController = () => {
    const queryParams = () => window.location.hash.slice(1);

    if (typeof window !== 'undefined') {
      const manager = buildUrlManager(headlessSearchEngine, { initialState: { fragment: queryParams() } });

      const onHashChange = () => {
        manager.synchronize(queryParams());
      };

      manager.subscribe(() => {
        pushNewHashToUrl(`#${manager.state.fragment}`);
      });

      window.addEventListener('hashchange', onHashChange);
      return () => window.removeEventListener('hashchange', onHashChange);
    }
  };

  const buildControllers = useCallback(() => {
    buildSearchParameterManagerController();
    buildResultsPerPage(headlessSearchEngine, { initialState: { numberOfResults: DEFAULT_RESULTS_COUNT } });
    const { logSearchFromLink } = loadSearchAnalyticsActions(headlessSearchEngine);
    headlessSearchEngine.executeFirstSearch(logSearchFromLink());
  }, []);

  const updateDocsAISearchEnabled = async () => {
    try {
      const { companyId } = state;

      const isDocsAISearchFeatureEnabled = await featureFlag.isDocsAISearchEnabled(
        companyId,
        accessToken
      );

      const isDocsAIRegenerateResponseFeatureEnabled = await featureFlag.isDocsAIRegenerateResponseEnabled(
        companyId,
        accessToken
      );

      setIsDocsAISearchEnabled(isDocsAISearchFeatureEnabled);
      setIsDocsAIRegenerateResponseEnabled(isDocsAIRegenerateResponseFeatureEnabled);
    } catch (e) {
      setIsDocsAISearchEnabled(false);
      setIsDocsAIRegenerateResponseEnabled(false);
    }
  };

  useEffect(() => {
    buildControllers();
    updateDocsAISearchEnabled();
  }, []);

  useEffect(() => {
    const updateIsOutofScopeLanguage = async () => {
      const isAllLanguagesEnabled = await featureFlag.isDocsAIAllLanguagesEnabled(state.companyId, accessToken);
      setIsOutofScopeLanguage(i18n.language !== 'en' && !isAllLanguagesEnabled);
    };
    updateIsOutofScopeLanguage();
  }, [ i18n.language ]);

  return (
    <>
      <Helmet>
        <title>
          {t('search_page_title', 'Search | Customer Portal')}
        </title>
      </Helmet>

      <WhiteBackgroundBody>
        <BodySection data-testid='CustomerPortalPageSearch__Body'>
          <Main>
            <SearchBarSection engine={headlessSearchEngine} />
            { isDocsAISearchEnabled && !isOutofScopeLanguage &&
              (<DocsGptSection
                engine={headlessSearchEngine}
                showRegenerateButton={isDocsAIRegenerateResponseEnabled} />)}
            <ResultsSection engine={headlessSearchEngine} />
            <PaginationSection engine={headlessSearchEngine} />
          </Main>
          <Side>
            <FacetsSection engine={headlessSearchEngine} />
          </Side>
        </BodySection>
      </WhiteBackgroundBody>
    </>
  );
};

export default CustomerPortalPageSearch;

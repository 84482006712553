import type { Locale } from '@customer-portal/constants';

export interface DropDownItemData {
  _id: string;
  label: string;
  value: string;
  sfdcIndex?: number; // Some fields may store the original ordering from Salesforce
  languages?: {
    [locale in Locale]?: {
      value: string;
    };
  };
}

export type ProductWithDependentFields = DropDownItemData & {
  productComponentVersions?: DropDownItemData[];
  packageNames?: DropDownItemData[];
  deploymentModels?: DropDownItemData[];
};

export type DeploymentTypeWithProducts = DropDownItemData & {
  products?: ProductWithDependentFields[];
  automationSuiteVersions?: DropDownItemData[];
};

export interface DeprecatedProductComponentVersion {
  deploymentTypeValue?: string;
  productValue: string;
  productComponentVersionValue: string;
  mainstreamSupportEndDate: string;
  extendedSupportEndDate: string;
  extendedSupportEndDateForGoldAndPlatinum: string;
}

export enum DynamicSupportFormField {
  studioOrRobotVersion = 'studioOrRobotVersion',
  productComponentVersion = 'productComponentVersion',
  deploymentModel = 'deploymentModel',
  orchestratorVersion = 'orchestratorVersion',
  siteUrl = 'siteUrl',
  packageVersion = 'packageVersion',
  packageName = 'packageName',
  environment = 'environment',
  automationSuiteVersion = 'automationSuiteVersion',
  airgapped = 'airgapped',
  architecture = 'architecture',
  issueType = 'issueType',
  license = 'license',
}

export type SupportFormFieldMappings = {
  deploymentTypeValue: string;
  productValue: string;
  logMessage: string[];
} & { [key in DynamicSupportFormField]?: {
  isRequired: boolean;
  isPrimaryVersionPicklist?: boolean;
  values?: string[];
}; };

export interface SupportFormData {
  countries: DropDownItemData[];
  environments: DropDownItemData[];
  issueCategories: DropDownItemData[];
  licenseCategories: DropDownItemData[];
  noOfRobotsImpacted: DropDownItemData[];
  orchestratorVersions: DropDownItemData[];
  priorities: DropDownItemData[];
  productRelatedFeatures: DropDownItemData[];
  deploymentTypes: DeploymentTypeWithProducts[];
  studioVersions: DropDownItemData[];
  supportRequestRelatedProducts: DropDownItemData[];
  timezones: DropDownItemData[];
  deprecatedProductComponentVersions: DeprecatedProductComponentVersion[];
  supportFormFieldMappings: SupportFormFieldMappings[];
  automationSuiteVersions: DropDownItemData[];
  airgapped: DropDownItemData[];
  architectures: DropDownItemData[];
  issueTypes: DropDownItemData[];
}

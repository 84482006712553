export enum CompanyAccountType {
  STANDARD = 'Standard',
  PREMIUM = 'Premium',
  PREMIUM_PLUS = 'Premium Plus',
  PREMIUM_SUPPORT = 'Premium Support',
  BASIC = 'Basic',
  ACTIVATE = 'Activate',
  ENTERPRISE = 'Enterprise',
  GOLD_TIER = 'Gold Tier',
  PLATINUM_TIER = 'Platinum Tier',
}

export const ALL_NONSTANDARD_COMPANY_ACCOUNT_TYPES = [
  CompanyAccountType.PREMIUM,
  CompanyAccountType.PREMIUM_PLUS,
  CompanyAccountType.PREMIUM_SUPPORT,
  CompanyAccountType.ACTIVATE,
  CompanyAccountType.ENTERPRISE,
  CompanyAccountType.GOLD_TIER,
  CompanyAccountType.PLATINUM_TIER,
];

export const ALL_TIER_2_NONSTANDARD_COMPANY_ACCOUNT_TYPES = [
  CompanyAccountType.GOLD_TIER,
  CompanyAccountType.PLATINUM_TIER,
];

import '@uipath/apollo-fonts/font.css';

import type {
  SearchBoxProps,
  SearchBoxState,
  SearchEngine,
  Suggestion,
} from '@coveo/headless';
import { buildSearchBox } from '@coveo/headless';
import type { Locale } from '@customer-portal/constants';
import type {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  AutocompleteRenderInputParams,
} from '@material-ui/lab';
import { Autocomplete } from '@material-ui/lab';
import {
  Button,
  Paper,
  TextField,
} from '@mui/material';
import { debounce } from 'lodash';
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';

import ApolloIcon from '../../../components/ApolloIcon';
import {
  SEARCH_MAXIMUM_CHARACTER_LENGTH,
  SEARCH_MINIMUM_CHARACTER_LENGTH,
  SEARCH_SUGGESTIONS_DELAY_MS,
} from '../../../constants/search.constants';
import { DEFAULT_SEARCH_PROPS } from '../../../pages/Search/SearchBar/constants';
import { KeyboardInputs } from '../../../pages/Search/SearchBar/types';
import { getSearchRedirectUrl } from '../../../utils/search';

const Container = styled.div`
  margin: auto;
  width: 663px;
  height: 60px;
  display: flex;
  margin-top: 16px;
  background-color: ${p => p.theme.palette.semantic.colorBackground};

  ${p => p.theme.breakpoints.down('sm')} {
    width: 550px;
  }

  ${p => p.theme.breakpoints.down('xs')} {
    width: 360px;
  }
`;

const Search = styled.div`
  width: inherit;
  height: inherit;
`;

const AutocompleteStyled = styled(Autocomplete)`
  display: flex;
  flex-grow: 1;
  width: inherit;
  height: inherit;
  padding: unset;
  margin: unset;

  .MuiAutocomplete-inputRoot {
    padding: ${p => p.theme.spacing(1)}px !important;
    flex-wrap: unset;
    border: 1px solid ${p => p.theme.palette.grey[400]};
  }
`;

const SuggestionListItem = styled.li`
  font-size: 1.6rem;
  color: ${p => p.theme.palette.semantic.colorForeground};
`;

const SearchBarStyled = styled(TextField)`
  width: inherit;
  height: inherit;
  margin-bottom: 10px;
  background-color: ${p => p.theme.palette.semantic.colorBackground};

  // Input's parent
  .MuiInputBase-root {
    height: inherit;
    padding: ${p => p.theme.spacing(1)}px ${p => p.theme.spacing(1)}px;
    border-radius: ${p => p.theme.spacing(1)}px;
    color: ${p => p.theme.palette.semantic.colorForeground};
    background-color: ${p => p.theme.palette.semantic.colorBackground};
    border: 1px solid ${p => p.theme.palette.semantic.colorBorder};
  }

  // Input element
  input.MuiInputBase-input {
    font-size: 1.6rem !important;
    background-color: ${p => p.theme.palette.semantic.colorBackground};
  }

  // Error label
  .MuiInputBase-root.Mui-error::after {
    display: none;
  }

  // Helper text
  .MuiFormHelperText-root.Mui-error {
    margin: ${p => p.theme.spacing(1.25)}px 0;
    line-height: 0;
    font-size: 1.2rem;
    color: ${p => p.theme.palette.error.main};
  }

  .MuiInputBase-input {
    border: none;
  }

  .MuiInputBase-root.MuiOutlinedInput-root {
    border: 1px solid ${p => p.theme.palette.grey[500]} !important;
    &:hover, &:focus, &:focus-within, &:active {
      border-color: transparent !important;
    }
  }
`;

const SearchBarAdornmentButton = styled(Button)`
  && {
    background-color: ${p => p.theme.palette.semantic.colorPrimary};
    padding: unset;
    margin: unset;
    box-shadow: none;
    height: 40px;
    width: 45px;
    min-width: unset;
    padding: unset;
    border-radius: ${p => p.theme.spacing(1)}px;
  }
`;

const SearchBarAdornmentIcon = styled(ApolloIcon)`
  color: ${p => p.theme.palette.semantic.colorBackground};

  && {
    font-size: ${p => p.theme.spacing(3.5)}px;
  }
`;

interface Props {
  engine: SearchEngine;
  searchBoxProps?: SearchBoxProps;
  id?: string;
  className?: string;
  onSearchCallback?: (
    event: React.ChangeEvent<{}>,
    value: unknown,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<unknown> | undefined
  ) => void;
}

const SearchBar = (props: Props) => {
  const {
    engine,
    onSearchCallback,
    searchBoxProps = DEFAULT_SEARCH_PROPS,
    className = '',
    id,
  } = props;
  const {
    i18n, t,
  } = useTranslation('common');
  const theme = useTheme();

  if (id && searchBoxProps.options) {
    searchBoxProps.options.id = id;
  }

  const searchBoxControllerRef = useRef(buildSearchBox(engine, searchBoxProps));
  const [ searchBoxState, setSearchBoxState ] = useState<SearchBoxState>(
    searchBoxControllerRef.current.state
  );
  const [ searchText, setSearchText ] = useState<string>('');
  const [ suggestionClicked, setSuggestionClicked ] = useState<{
    clicked: boolean;
    suggestion: string;
  }>({
    clicked: false,
    suggestion: '',
  });
  const [ isInputError, setIsInputError ] = useState<{ [inputError: string]: boolean }>({});
  const [ locale, setLocale ] = useState<Locale>(i18n.language as Locale);

  useEffect(() => {
    setLocale(i18n.language as Locale);
  }, [ i18n.language ]);

  useEffect(() => {
    const {
      clicked, suggestion,
    } = suggestionClicked;

    if (clicked && suggestion) {
      onSearch(suggestion);
      setSuggestionClicked({
        clicked: false,
        suggestion: '',
      });
    }
  }, [ suggestionClicked ]);

  const setAndSubscribeController = useCallback(() => {
    const cancelSubscription = searchBoxControllerRef.current.subscribe(() => {
      // do stuff with the suggestions
      setSearchBoxState(searchBoxControllerRef.current.state);
    });

    return () => {
      cancelSubscription && cancelSubscription();
    };
  }, []);

  useEffect(setAndSubscribeController, [ setAndSubscribeController ]);

  const debouncedControllerUpdateText = useCallback(
    debounce((value: string) => {
      searchBoxControllerRef.current.updateText(value);
    }, SEARCH_SUGGESTIONS_DELAY_MS),
    []
  );

  const onSearchTextChange = (
    e: React.ChangeEvent<{}>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    switch (reason) {
      case 'clear': {
        setSearchText(value);
        searchBoxControllerRef.current.updateText(value);
        setIsInputError({});
        break;
      }
      case 'input': {
        setSearchText(value);
        value.length > 0
          ? debouncedControllerUpdateText(value)
          : searchBoxControllerRef.current.clear();
        setIsInputError({});
        break;
      }
      default: {
        // When reason is 'reset'
        // Triggered when 'Enter' key is pressed.
        // Do nothing. let component handle enter
        break;
      }
    }
  };

  const onSearch = (autocompleteText?: string) => {
    const text: string = autocompleteText ?? searchText;
    if (text.length < SEARCH_MINIMUM_CHARACTER_LENGTH) {
      setIsInputError({ lessThanMinimumCharacters: true });
      return;
    } else if (text.length > SEARCH_MAXIMUM_CHARACTER_LENGTH) {
      setIsInputError({ moreThanMaximumCharacters: true });
      return;
    }

    // Redirects the user to a search results page while replacing the current URL in browser history with the search query and language
    // This means clicking "back" won't return to this page
    // text is the search query and locale is the language of the user
    window.location.replace(getSearchRedirectUrl({
      text,
      locale,
    }));
  };

  const autocompleteChange = (
    e: React.ChangeEvent<any>,
    value: any,
    reason: AutocompleteChangeReason
  ) => {
    const text = (value as Suggestion)?.rawValue;

    // Clearing text should not execute search
    // Have 'select-option' (which is the reason on 'Enter') handled
    // by setting the 'suggestionClicked' state and letting the 'useEffect'
    // and 'onAutocompleteKeyDown' handling the rest
    const EXCLUDED_REASONS: Set<AutocompleteChangeReason> = new Set([
      'clear',
      'create-option',
    ]);

    if (!EXCLUDED_REASONS.has(reason)) {
      onSearchTextChange(e, text, 'input');
      setSuggestionClicked({
        clicked: true,
        suggestion: text,
      });
    }
  };

  const onAutocompleteKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    // Only run submit from 'searchText' if suggestion option wasn't clicked
    if (!suggestionClicked.clicked && e.key === KeyboardInputs.ENTER) {
      onSearch();
    }
  };

  return (
    <Container>
      <Search
        className={className}
        data-testid='SearchBarContainer'
      >
        <AutocompleteStyled
          freeSolo
          inputValue={searchText}
          onChange={onSearchCallback ? onSearchCallback : autocompleteChange}
          onInputChange={onSearchTextChange}
          options={searchBoxState.suggestions}
          getOptionLabel={(suggestion: any) => (
            (suggestion as Suggestion)?.rawValue ?? ''
          )}
          filterOptions={(suggestion: any) => suggestion}
          renderOption={(suggestion: any) => (
            <SuggestionListItem>
              {(suggestion as Suggestion).highlightedValue}
            </SuggestionListItem>
          )}
          onKeyDown={onAutocompleteKeyDown}
          renderInput={(params: AutocompleteRenderInputParams) => (
            <SearchBarStyled
              {...params}
              data-testid='HomePageSearch__Input'
              error={isInputError.lessThanMinimumCharacters || isInputError.moreThanMaximumCharacters}
              helperText={isInputError.lessThanMinimumCharacters
                ? t('global_search_minimum_characters_error', 'Search term must be 3 characters or longer in length')
                : isInputError.moreThanMaximumCharacters ? t('global_search_maximum_characters_error', 'Search term must be 4000 characters or shorter in length')
                  : ''}
              variant={'outlined' as any}
              placeholder={t(
                'homepage_search_placeholder',
                'Ask a question to search UiPath\'s knowledge base'
              )}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <SearchBarAdornmentButton
                    data-testid='HomePageSearch__SubmitButton'
                    variant='contained'
                    onClick={() => onSearch()}
                  >
                    <SearchBarAdornmentIcon
                      icon='search'
                      className='search'
                    />
                  </SearchBarAdornmentButton>
                ),
              }}
            />
          )}
          PaperComponent={(props: any) => (
            <Paper
              {...props}
              style={{ backgroundColor: theme.palette.semantic.colorBackgroundRaised }}
            />
          )}
        />
      </Search>
    </Container>
  );
};

export default SearchBar;

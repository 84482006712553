import {
  ASM_EMAIL,
  UiPathContactRole,
} from '@customer-portal/constants/dist/sfdc.constants';
import React, { useContext } from 'react';
// Translations
import { useTranslation } from 'react-i18next';

// Constants
import { VIEW_AVATAR_URL } from '../../../constants/network.constants';
import { COMPANY_CONTACTS } from '../../../constants/telemetry.constants';
// Data
import type { IDataObject } from '../../../interfaces/dataObject.interface';
import { useTrackPageViewEvent } from '../../../lib/AppInsights/AppInsights';
import { StoreContext } from '../../../store';
// Components
import Loader from '../../CustomerPortal-Loader';
import UserCard from '../../user_card/User-Card';

type CompanyBodyContactsProps = {
  loading: boolean;
};

const CompanyBodyContacts = ({ loading = true }: CompanyBodyContactsProps) => {
  // Translate method
  const { t } = useTranslation('common');

  const baseClass = 'MyCompanyBody';
  const { state } = useContext(StoreContext);
  const contacts = state.uiPathContacts ?? [];

  // Lifecycle
  useTrackPageViewEvent(COMPANY_CONTACTS);

  // Methods
  const setProfilePhoto = (profilePhoto: string) => {
    if (!profilePhoto) {
      return '';
    }

    return `${VIEW_AVATAR_URL}/${profilePhoto}`;
  };

  const generateContacts = () => {
    if (contacts?.length > 0) {
      return contacts.map((contact: IDataObject, i: number) => {
        if (contact) {
          const name: string = contact.name || '';
          const photo: string = contact.profilePhoto || '';
          const role: string = contact.role || '';
          const email: string = role === UiPathContactRole.ASM ? ASM_EMAIL : contact.email?.replace('.invalid', '');
          return (
            <UserCard
              name={name}
              email={email}
              avatarUrl={setProfilePhoto(photo)}
              role={role}
              key={i}
            />
          );
        }
        return undefined;
      });
    }
    return (
      <p
        className={`${baseClass}__No-Licenses-Text`}
        data-testid="EmptyState"
      >
        {t(
          'company_uipath_contacts_no_results',
          `No UiPath Contacts for ${state.companyName}`,
          { company_name: state.companyName }
        )}
      </p>
    );

  };

  return (
    <div
      className={`${baseClass}__Contacts`}
      data-testid="CompanyBodyContacts">
      <h3 className={`${baseClass}__Body-Heading Regular`}>
        {t('company_uipath_contact_title_text', 'Your dedicated UiPath Team')}
      </h3>
      <div className={`${baseClass}__Heading-Text`}>
        <h5 className={`${baseClass}__Body-Subheading Regular`}>
          {t(
            'company_uipath_contacts_description',
            'Your assigned UiPath contacts are listed below. Reach out to them whenever you need.'
          )}
        </h5>
      </div>
      <div className={`${baseClass}__List`}>
        {loading ? (
          <div className={`${baseClass}__Loader`}>
            <Loader />
          </div>
        ) : (
          generateContacts()
        )}
      </div>
    </div>
  );
};

export default CompanyBodyContacts;

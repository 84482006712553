import {
  ALL_CASE_STATUS_FILTERS,
  CLOSED_CASE_STATUS_FILTERS,
  CompanyAccountType,
  OPEN_CASE_STATUS_FILTERS,
} from '@customer-portal/constants';
import type { IStatusUpdatesIncident } from '@customer-portal/interfaces';
import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import Helmet from 'react-helmet';
// Translations
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import { Redirect } from 'react-router-dom';

// Styles
import * as styles from '../../../assets/css/CustomerPortalBrowseClients';
import ApolloIcon from '../../../components/ApolloIcon';
import Button from '../../../components/Button/Button';
// Google Analytics
import { CustomerPortalGoogleAnalyticsPageView } from '../../../components/CustomerPortal-GoogleAnalytics';
import Loader from '../../../components/CustomerPortal-Loader';
import Container from '../../../components/CustomerPortal-New-Container';
import Pagination from '../../../components/CustomerPortal-Pagination';
import SelectWithSearch from '../../../components/Select-With-Search';
// components
import CustomerPortalSupportHeader from '../../../components/support/CustomerPortal-Support-Header';
import CustomerPortalSupportUploadFile from '../../../components/support/CustomerPortal-Support-UploadFile';
// Constants
import { SUPPORT_PREMIUM } from '../../../constants/telemetry.constants';
// interfaces
import type { ISupportList } from '../../../interfaces/supportTickets.interface';
import { useTrackPageViewEvent } from '../../../lib/AppInsights/AppInsights';
import { UserPermissionsHelper } from '../../../lib/UserPermissions';
import { StoreContext } from '../../../store';

type PremiumPageProps = {
  isLoading: boolean;
  incidentsList: ISupportList[];
  isEntityFiltersEnabled: boolean;
  statusUpdatesIncidents: IStatusUpdatesIncident[] | null;
  isStatusUpdatesEnabled: boolean;
};

interface ITranslationStringObject {
  textKey: string;
  fallbackText: string;
  data?: { [key: string]: string };
}

const PremiumSupport = (props: PremiumPageProps) => {
  // Translate method
  const { t } = useTranslation('common');
  /* Default constants */
  const { isLoading } = props;

  const canAddNewCase = UserPermissionsHelper.isEditPremiumSupportAllowed();
  const canView360Questionnaire: boolean = UserPermissionsHelper.isView360QuestionnaireAllowed();
  const canShowPremiumCases = !UserPermissionsHelper.isActivateSuccessCustomer();
  const [ pageNumber, setPageNumber ] = useState(1);
  const [ searchInput, setSearchInput ] = useState(false);
  const [ searchInputText, setSearchInputText ] = useState('');
  const [ sortInputText, setSortInputText ] = useState(
    t(
      'support_premium_support_cases_sort_by_requested_start_date',
      'Request Start Date'
    )
  );
  const [ hoveredItem, setHoveredItem ] = useState('');
  const [ statusFilters, setStatusFilters ] = useState(OPEN_CASE_STATUS_FILTERS);
  const [ gotoDetails, setGotoDetails ] = useState(false);
  const [ selectedTicket, setSelectedTicket ] = useState('');
  const [ selectedList, setSelectedList ] = useState<ISupportList[]>([]);
  const [ openTickets, setOpenTickets ] = useState(props.incidentsList.filter(function(el: any) {
    if (el.Subject !== null) {
      return OPEN_CASE_STATUS_FILTERS.includes(el.Status);
    }
    return false;
  }).length);

  const [ closedTickets, setClosedTickets ] = useState(props.incidentsList.filter(function(el: any) {
    if (el.Subject !== null) {
      return OPEN_CASE_STATUS_FILTERS.includes(el.Status);
    }
    return false;
  }).length);

  // Global state from Store
  const { state } = useContext(StoreContext);

  const isPremiumSupport = state.companyType === CompanyAccountType.PREMIUM_SUPPORT;

  const computedTranslations: {
    heroTitle: ITranslationStringObject;
    heroSubTitle: ITranslationStringObject;
    filterOpenCases: ITranslationStringObject;
    filterClosedCases: ITranslationStringObject;
    filterAllCases: ITranslationStringObject;
    browseTitle: ITranslationStringObject;
  } = isPremiumSupport
    ? // All Premium Support text
    {
      heroTitle: {
        textKey: 'support_premium_support_cases_hero_title',
        fallbackText: `${state.companyName} \nPremium Support Requests`,
        data: { company_name: state.companyName },
      },
      heroSubTitle: {
        textKey: 'support_premium_support_cases_hero_subTitle',
        fallbackText:
            'Check below the status of your Premium Support Requests for the past 6 months',
      },
      filterOpenCases: {
        textKey:
            'support_premium_support_cases_filter_item_open_premium_support_cases',
        fallbackText: 'Open Premium Support Requests',
      },
      filterClosedCases: {
        textKey:
            'support_premium_support_cases_filter_item_closed_premium_support_cases',
        fallbackText: 'Closed Premium Support Requests',
      },
      filterAllCases: {
        textKey:
            'support_premium_support_cases_filter_item_all_premium_support_cases',
        fallbackText: 'All Premium Support Requests',
      },
      browseTitle: {
        textKey: 'support_premium_support_cases_browse_title',
        fallbackText: 'Browse all Support Requests',
      },
    }
    : // All Premium and Premium Plus text
    {
      heroTitle: {
        textKey: 'support_premium_care_cases_hero_title',
        fallbackText: `${state.companyName} \nPreventive Care Activities`,
        data: { company_name: state.companyName },
      },
      heroSubTitle: {
        textKey: 'support_premium_care_cases_hero_subTitle',
        fallbackText:
            'Check below the status of your Preventive Care Activity Requests for the past 6 months',
      },
      filterOpenCases: {
        textKey:
            'support_premium_support_cases_filter_item_open_premium_care_cases',
        fallbackText: 'Open Preventive Care Activity Requests',
      },
      filterClosedCases: {
        textKey:
            'support_premium_support_cases_filter_item_closed_premium_care_cases',
        fallbackText: 'Closed Preventive Care Activity Requests',
      },
      filterAllCases: {
        textKey:
            'support_premium_support_cases_filter_item_all_premium_care_cases',
        fallbackText: 'All Preventive Care Activity Requests',
      },
      browseTitle: {
        textKey: 'support_premium_care_cases_browse_title',
        fallbackText: 'Browse all Activity Requests',
      },
    };

  // Search
  const searchInputActiveClass: string =
    ' CustomerPortalClientsSelection__clientsSearchInput--active';
  const closeSearchInputClass: string =
    ' CustomerPortalClientsSelection__clientsSearchIcon--close';

  // Pagination settings
  const limit = 10;
  const start = (pageNumber - 1) * limit;
  const end = pageNumber * limit;

  const handleSort = (selectedOption: any) => {
    setSortInputText(selectedOption.label);
  };

  // Search
  const handleSearch = (e: any) => {
    e.preventDefault();
    if (!searchInput) {
      setSearchInput(true);
    } else {
      setSearchInput(false);
      setSearchInputText('');
    }
  };

  const handleSearchInput = (e: any) => {
    setSearchInputText(e.target.value);
  };

  const handlePaginateClick = (page: number) => {
    setPageNumber(page);
  };

  const getDetails = (ticket_id: any) => (
    event: React.MouseEvent<HTMLLIElement>
  ) => {
    setSelectedTicket(ticket_id);
    setGotoDetails(true);
  };

  useTrackPageViewEvent(SUPPORT_PREMIUM);

  useEffect(() => {
    setPageNumber(1);
    setSelectedList(
      props.incidentsList
        .filter(el => statusFilters.includes(el.Status))
        .filter(
          el => {
            const normalizedInput = searchInputText.toLowerCase();
            return (
              (el.Subject?.toLowerCase().includes(normalizedInput)) ||
              el.CreatedDate.toLowerCase().includes(normalizedInput) ||
              el.CaseNumber.toLowerCase().includes(normalizedInput) ||
              el.Id.toLowerCase().includes(normalizedInput)
            );
          }
        )
        .sort((a, b) => {
          if (sortInputText === 'Subject') {
            return a.Subject > b.Subject ? 1 : -1;
          } else if (sortInputText === 'Status') {
            return a.Status > b.Status ? -1 : 1;
          } else if (sortInputText === 'Request Start Date') {
            return a.Start_Date__c > b.Start_Date__c ? -1 : 1;
          }
          return a.CreatedDate > b.CreatedDate ? -1 : 1;
        })
    );
  }, [ searchInputText, statusFilters, sortInputText, props.incidentsList ]);

  useEffect(() => {
    CustomerPortalGoogleAnalyticsPageView('Support Premium');
  }, []);

  useEffect(() => {
    const filteredList = props.incidentsList
      .filter(
        el => {
          const normalizedInput = searchInputText.toLowerCase();
          return (
            (el.Subject?.toLowerCase().includes(searchInputText.toLowerCase())) ||
              el.CreatedDate.toLowerCase().includes(normalizedInput) ||
              el.CaseNumber.toLowerCase().includes(normalizedInput) ||
              el.Id.toLowerCase().includes(normalizedInput)
          );
        }
      );
    setOpenTickets(filteredList.filter(function(el: any) {
      if (el.Subject !== null) {
        return OPEN_CASE_STATUS_FILTERS.includes(el.Status);
      }
      return false;
    }).length);

    setClosedTickets(filteredList.filter(function(el: any) {
      if (el.Subject !== null) {
        return CLOSED_CASE_STATUS_FILTERS.includes(el.Status);
      }
      return false;
    }).length);
  }, [ props.incidentsList, searchInputText ]);

  if (gotoDetails) {
    return (
      <Redirect
        push
        to={{
          pathname: `/support/details/${selectedTicket}`,
          state: { referrer: 'cases-premium' },
        }}
      />
    );
  }

  return (
    <>
      <Helmet>
        <title>
          {t('premium_cases_page_title', 'Premium Cases | Customer Portal')}
        </title>
      </Helmet>
      <styles.ClientsSelection>
        <CustomerPortalSupportHeader
          title={t(
            computedTranslations.heroTitle.textKey,
            computedTranslations.heroTitle.fallbackText,
            computedTranslations.heroTitle.data
          )}
          subtitle={
            canShowPremiumCases
              ? t(
                computedTranslations.heroSubTitle.textKey,
                computedTranslations.heroSubTitle.fallbackText
              )
              : ''
          }
          showStickerText
        />
        {/* Upload 360 questionnaire to SFDC */}
        {canView360Questionnaire && <CustomerPortalSupportUploadFile />}
        {canShowPremiumCases && (
          <Container cssClass="CustomerPortalClientsSelection__container">
            <styles.Sidebar data-testid="Sidebar">
              <styles.Filter>
                <h6 className="CustomerPortalClientsSelection__filterTitle">
                  {t('support_premium_support_cases_filter_title', 'Filter by')}
                </h6>
                <styles.FilterItem
                  active={statusFilters === OPEN_CASE_STATUS_FILTERS}
                  onClick={(e: any) => {
                    e.preventDefault();
                    setStatusFilters(OPEN_CASE_STATUS_FILTERS);
                  }}
                  data-testid="OpenCases"
                >
                  <span
                    className="CustomerPortalClientsSelection__filterItemValue"
                    data-testid="OpenCasesCount"
                  >
                    {openTickets ? openTickets : '0'}
                  </span>
                  <span className="CustomerPortalClientsSelection__filterItemName">
                    {t(
                      computedTranslations.filterOpenCases.textKey,
                      computedTranslations.filterOpenCases.fallbackText
                    )}
                  </span>
                </styles.FilterItem>
                <styles.FilterItem
                  active={statusFilters === CLOSED_CASE_STATUS_FILTERS}
                  onClick={(e: any) => {
                    e.preventDefault();
                    setStatusFilters(CLOSED_CASE_STATUS_FILTERS);
                  }}
                  data-testid="ClosedCases"
                >
                  <span
                    className="CustomerPortalClientsSelection__filterItemValue"
                    data-testid="ClosedCasesCount"
                  >
                    {closedTickets ? closedTickets : '0'}
                  </span>
                  <span className="CustomerPortalClientsSelection__filterItemName">
                    {t(
                      computedTranslations.filterClosedCases.textKey,
                      computedTranslations.filterClosedCases.fallbackText
                    )}
                  </span>
                </styles.FilterItem>
                <div className="CustomerPortalClientsSelection__filterItems">
                  <styles.FilterItem
                    active={statusFilters === ALL_CASE_STATUS_FILTERS}
                    onClick={(e: any) => {
                      e.preventDefault();
                      setStatusFilters(ALL_CASE_STATUS_FILTERS);
                    }}
                    data-testid="AllCases"
                  >
                    <span
                      className="CustomerPortalClientsSelection__filterItemValue"
                      data-testid="AllCasesCount"
                    >
                      {openTickets + closedTickets}
                    </span>
                    <span className="CustomerPortalClientsSelection__filterItemName">
                      {t(
                        computedTranslations.filterAllCases.textKey,
                        computedTranslations.filterAllCases.fallbackText
                      )}
                    </span>
                  </styles.FilterItem>
                </div>
              </styles.Filter>
            </styles.Sidebar>

            <styles.ClientsContent className="CustomerPortalClientsSelection__content">
              <div className="CustomerPortalClientsSelection__clientsHeader">
                <p className="CustomerPortalClientsSelection__clientsLeadText">
                  {t(
                    computedTranslations.browseTitle.textKey,
                    computedTranslations.browseTitle.fallbackText
                  )}
                </p>

                <div className="CustomerPortalClientsSelection__clientsActions">
                  <SelectWithSearch
                    options={[
                      {
                        label: t(
                          'support_premium_support_cases_sort_by_requested_start_date',
                          'Request Start Date'
                        ),
                        value: 'request_start_date',
                      },
                      {
                        label: t(
                          'support_premium_support_cases_sort_by_date',
                          'Date'
                        ),
                        value: 'date',
                      },
                      {
                        label: t(
                          'support_premium_support_cases_sort_by_subject',
                          'Subject'
                        ),
                        value: 'subject',
                      },
                      {
                        label: t(
                          'support_premium_support_cases_sort_by_status',
                          'Status'
                        ),
                        value: 'status',
                      },
                    ]}
                    value={sortInputText}
                    className="Custom-Select--White CustomerPortalClientsSelection__clientsSort"
                    onChange={handleSort}
                    searchable={false}
                    placeholder={
                      <div
                        className="CustomerPortalClientsSelection__TextWrapper"
                        data-testid="sort-by"
                      >
                        {t('sort_by', 'Sort by')}
:
                        {' '}
                        <span className="Custom-Select__Placeholder-Value">
                          {sortInputText}
                        </span>
                      </div>
                    }
                  />
                  <div
                    className="CustomerPortalClientsSelection__clientsSearch"
                    data-testid="search-button"
                  >
                    <div
                      className={`CustomerPortalClientsSelection__clientsSearchInput${
                        searchInput ? searchInputActiveClass : ''
                      }`}
                    >
                      {searchInput && (
                        <input
                          autoFocus
                          placeholder={t(
                            'support_premium_support_cases_search_placeholder',
                            'Keyword'
                          )}
                          type="text"
                          value={searchInputText}
                          onChange={handleSearchInput}
                          data-testid="search-input"
                        />
                      )}
                    </div>
                    <div
                      className={`CustomerPortalClientsSelection__clientsSearchIcon${
                        searchInput ? closeSearchInputClass : ''
                      }`}
                      onClick={handleSearch}
                    >
                      <ApolloIcon
                        icon={searchInput ? 'close' : 'search'}
                        fontSize="small"
                        className="icon"
                      />
                    </div>
                  </div>
                  <Button
                    className="CustomerPortalClientsSelection__addNewTicketBtn"
                    href="/support/premium-care/add-case"
                    target="_self"
                    text={t(
                      'support_premium_support_cases_add_new_request_btn',
                      'Add New Request'
                    )}
                    disabled={!canAddNewCase}
                  />
                </div>
              </div>
              <ul className="CustomerPortalClientsSelection__clientsList">
                {selectedList.length !== 0 && (
                  <li className="CustomerPortalClientsSelection__clientsListItem CustomerPortalSupport--premiumListItem CustomerPortalClientsSelection__clientsListItem--tableHeader">
                    <div className="CustomerPortalClientsSelection__client CustomerPortalClientsSelection__client--noBookmarkStar">
                      <h6 className="CustomerPortalClientsSelection__clientName">
                        {t(
                          'support_premium_support_cases_column_subject',
                          'Subject'
                        )}
                      </h6>
                    </div>
                    <div className="CustomerPortalClientsSelection__clientDetails CustomerPortalSupport--premiumMetaData">
                      <p
                        style={{ width: '28%' }}
                        className="CustomerPortalClientsSelection__clientCountry"
                      >
                        {t(
                          'support_premium_support_cases_column_request_start_date',
                          'Request Start Date'
                        )}
                      </p>
                      <p
                        style={{ width: '37%' }}
                        className="CustomerPortalClientsSelection__clientCountry"
                      >
                        {t(
                          'support_premium_support_cases_column_created_on',
                          'Created On'
                        )}
                      </p>
                      <p
                        style={{
                          width: '15%',
                          paddingRight: '10px',
                        }}
                        className="CustomerPortalClientsSelection__clientCountry"
                      >
                        {t(
                          'support_premium_support_cases_column_case_no',
                          'Case #'
                        )}
                      </p>
                      <p
                        style={{ width: '20%' }}
                        className="CustomerPortalClientsSelection__clientIndustry CustomerPortalSupport--premiumStatus"
                      >
                        {t(
                          'support_premium_support_cases_column_status',
                          'Status'
                        )}
                      </p>
                    </div>
                  </li>
                  /* To do: No clients error handling */
                )}
                {selectedList.length > 0 &&
                  selectedList
                    .slice(start, end)
                    .map(incident => (
                      <li
                        onMouseEnter={() => {
                          setHoveredItem(incident.Id);
                        }}
                        onMouseLeave={() => {
                          setHoveredItem('');
                        }}
                        key={incident.Id}
                        className="CustomerPortalClientsSelection__clientsListItem CustomerPortalClientsSelection__clientsListItem--tableRow CustomerPortalSupport--premiumListItem"
                        onClick={getDetails(incident.Id)}
                        data-testid="Case"
                      >
                        <div className="CustomerPortalClientsSelection__client CustomerPortalClientsSelection__client--noBookmarkStar">
                          <h6 className="CustomerPortalClientsSelection__clientName CustomerPortalSupport--premiumSubject">
                            {incident.Subject || ''}
                          </h6>
                        </div>

                        <div className="CustomerPortalClientsSelection__clientDetails CustomerPortalSupport--premiumMetaData">
                          <p
                            style={{ width: '28%' }}
                            className="CustomerPortalClientsSelection__clientCountry"
                          >
                            <Moment format="MMMM. DD YYYY">
                              {incident.Start_Date__c || ''}
                            </Moment>
                          </p>
                          <p
                            style={{ width: '37%' }}
                            className="CustomerPortalClientsSelection__clientCountry"
                          >
                            <Moment format="MMMM. DD YYYY &middot; hh:mm A">
                              {incident.CreatedDate || ''}
                            </Moment>
                          </p>
                          <p
                            style={{
                              width: '15%',
                              padding: '10px',
                            }}
                            className="CustomerPortalClientsSelection__clientCountry "
                          >
                            {incident.CaseNumber || ''}
                          </p>

                          {hoveredItem === incident.Id && (
                            <p
                              style={{ width: '20%' }}
                              className="CustomerPortalClientsSelection__clientCountry CustomerPortalClientsSelection__clientCountry--details CustomerPortalSupport--premiumStatus"
                            >
                              {t(
                                'support_premium_support_cases_status_hover_text',
                                'Details'
                              )}
                            </p>
                          )}

                          {hoveredItem !== incident.Id && (
                            <p
                              style={{ width: '20%' }}
                              className={`CustomerPortalClientsSelection__clientCountry CustomerPortalSupport--premiumStatus ${
                                CLOSED_CASE_STATUS_FILTERS.includes(incident.Status)
                                  ? 'CustomerPortalClientsSelection__clientCountry--closedTicket'
                                  : 'CustomerPortalClientsSelection__clientCountry--openTicket'
                              }`}
                            >
                              {CLOSED_CASE_STATUS_FILTERS.includes(incident.Status)
                                ? 'Closed'
                                : incident.Status || ''}
                            </p>
                          )}
                        </div>
                      </li>
                    ))}

                {isLoading && (
                  <div className="CustomerPortalClientsSelection__loader">
                    <Loader />
                  </div>
                )}

                {!isLoading && selectedList.length === 0 && (
                  <div
                    className="CustomerPortalClientsSelection__notFound"
                    data-testid="EmptyState"
                  >
                    <h2>
                      {t(
                        'support_premium_support_cases_no_results',
                        'No case found!'
                      )}
                    </h2>
                  </div>
                )}
              </ul>
              {selectedList.length > 0 && (
                <Pagination
                  activePage={pageNumber}
                  handleClick={handlePaginateClick}
                  numResults={selectedList.length}
                  numPagesBeforeEllipses={5}
                  numResultsPerPage={10}
                />
              )}
            </styles.ClientsContent>
          </Container>
        )}
      </styles.ClientsSelection>
    </>
  ); // End of return
};

export default PremiumSupport;

import { Header } from '@customer-portal/constants';
import type { EntityDTO } from '@customer-portal/interfaces';
import {
  FormControl,
  TextareaAutosize,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import _ from 'lodash';
import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import { Helmet } from 'react-helmet';
// Translations
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';

import { axiosGet } from '../../../client/axios';
import Button from '../../../components/Button/Button';
import ConfirmationModal from '../../../components/ConfirmationModal';
// Google Analytics
import { CustomerPortalGoogleAnalyticsPageView } from '../../../components/CustomerPortal-GoogleAnalytics';
import MultiEmailInput from '../../../components/MultiEmailInput';
import SelectWithSearch from '../../../components/Select-With-Search';
import TextLabel from '../../../components/TextLabel';
import { Tooltip } from '../../../components/Tooltip';
import {
  HAPO_DISPLAY_ANNOUNCEMENT_MODAL,
  HAPO_REMOVE_1ST_LEVEL_LICENSE,
  HAPO_REMOVE_2ND_LEVEL_LICENSE,
  HAPO_RESET_CART,
  HAPO_UPDATE_1ST_LEVEL_LICENSE_QUANTITY,
  HAPO_UPDATE_2ND_LEVEL_LICENSE_QUANTITY,
} from '../../../constants/hapoActions.constants';
import { ENTERPRISE_AGREEMENT_CHECKOUT } from '../../../constants/telemetry.constants';
import { useAuth } from '../../../contexts/auth';
import type { GlobalStoreState } from '../../../interfaces/contextStore';
import type { IHAPODataConfig } from '../../../interfaces/hapo.interface';
import { useTrackPageViewEvent } from '../../../lib/AppInsights/AppInsights';
import {
  getELAWarningInfo,
  getInvalidSKUsFromCart,
  getLicenseAvailableQuantity,
  isCartInvalidAgainstConfig,
} from '../../../lib/hapo.utils';
import {
  getActiveBundles,
  getActiveCloudBundles,
  getActiveProcessMiningBundles,
  getDeepCopyofProductsForAPI,
  getLicensesGroupedByType,
  getLicensesTypesInCart,
  isCartProductsInvalid,
  trimSKUName,
} from '../../../lib/requestForQuote.utils';
import type { HapoSkuType } from '../../../types/hapo.types';
import type { ApiSkuType } from '../../../types/requestForQuote.types';
import { featureFlag } from '../../../utils/featureFlag';
// styles
import * as styles from './../../../assets/css/RequestForQuote/Checkout';
// Components
import NoCompanyId from './../../../components/CustomerPortal-NoCompanyId';
// Constants
import {
  DEAL_TYPE_HAPO,
  FLEX_AI_UNITS_BUNDLE_60K,
  LICENSE_VERSIONS,
} from './../../../constants/hapo.constants';
import {
  BACKEND_HOST_NAME,
  ENTITIES_URL,
  HAPO_PRODUCTS_URL,
} from './../../../constants/network.constants';
import RFQ, {
  AUTOMATION_CLOUD,
  AUTOMATION_SUITE,
  CLOUD,
  EXISTING_AUTOMATION_SUITE,
  EXISTING_CLOUD,
  EXISTING_DEDICATED_AUTOMATION_CLOUD,
  EXISTING_ORCHESTRATOR,
  EXISTING_PROCESS_MINING,
  NEW_ORCHESTRATOR,
  NEW_PROCESS_MINING,
  SERVICE,
  STANDALONE,
} from './../../../constants/requestForQuote.constants';
import { UserPermissionsHelper } from './../../../lib/UserPermissions';
import { StoreContext } from './../../../store';

interface IFormData {
  accountId: string;
  accountName: string;
  requester: string;
  phoneNumber: string;
  duration: null | { label: string; value: number };
  activationDate: string;
  informList: string[];
  notes: string;
  errors: any;
}

const HapoCheckoutPage = (props: any) => {
  // Translate method
  const { t } = useTranslation('common');
  const baseClass = 'Checkout';
  const {
    state, dispatch,
  } = useContext(StoreContext);
  const {
    authType, accessToken,
  } = useAuth();
  const [ redirectToCatalog, setRedirectToCatalog ] = React.useState(false);
  const [ redirectToHapoDashboard, setRedirectToHapoDashboard ] = React.useState(
    false
  );
  const [ submitButtonLoading, setSubmitButtonLoading ] = React.useState(false);
  const [ isValid, setIsValid ] = React.useState(false);
  const [ formData, setFormData ] = React.useState<IFormData | any>({
    accountId: state.companyId,
    accountName: state.companyName,
    requester: state.userName,
    phoneNumber: state.phoneNumber,
    version: null,
    informList: [ '' ],
    notes: '',
    errors: {},
  });
  const isCartEmpty = !(state.hapoProducts?.length ?? 0);
  const availableLicensesIds: string[] = getActiveBundles(
    state.companyLicenses?.boonLicense?.licenses || []
  )
    .concat(
      getActiveProcessMiningBundles(state.companyLicenses?.boonLicense?.licenses) || []
    )
    .concat(getActiveCloudBundles(state.companyLicenses?.boonLicense?.licenses) || [])
    .map(license => license.licenseCode);
  const [ licenseInfo, setLicenseInfo ] = useState<{
    licenses: HapoSkuType[];
    config: IHAPODataConfig;
    dealType: string;
    dynamicOveragePercent?: number;
  }>({
    licenses: [],
    config: {
      individual_skus: null,
      groups: null,
    },
    dealType: DEAL_TYPE_HAPO,
  });
  const [ insufficientHapoQuantities, setInsufficientHapoQuantities ] = useState(
    false
  );

  const isEditHAPOLicenseAllowed = UserPermissionsHelper.isEditHAPOLicenseAllowed();
  useTrackPageViewEvent(ENTERPRISE_AGREEMENT_CHECKOUT);

  const confirmationModalInfo = state.hapoAnnouncementModalInfo;
  const confirmationModalMessage = confirmationModalInfo?.message;
  const openConfirmationModal = Boolean(confirmationModalMessage);

  const [ allEntities, setAllEntities ] = useState<Array<{ _id: string; name: string }>>([]);
  const [ isEntityManagementEnabled, setIsEntityManagementEnabled ] = useState<boolean>(false);
  const [ isDynamicOverageEnabled, setIsDynamicOverageEnabled ] = useState<boolean>(false);
  const [ selectedEntity, setSelectedEntity ] = useState<{ _id: string; name: string }>();
  const [ showVersionWarningModal, setShowVersionWarningModal ] = useState(false);
  const [ pendingVersion, setPendingVersion ] = useState<any>(null);

  const NONE_OPTION = {
    label: t('support_form_dropdown_item_none', '--- None ---'),
    value: 'None',
  };

  useEffect(() => {
    fetchFeatureFlags();
    fetchAllEntities();
  }, []);

  useEffect(() => {
    // Fetch Licenses Catalog Data
    const fetchLicencesData = async () => {
      try {
        const licenseAndCatalog = await axios.get(HAPO_PRODUCTS_URL, {
          headers: {
            [Header.AUTHORIZATION]: `Bearer ${accessToken}`,
            [Header.SELECTED_ACCOUNT_ID]: state.companyId,
            [Header.AUTH_TYPE]: authType,
          },
        });

        if (
          licenseAndCatalog.data &&
          Array.isArray(licenseAndCatalog.data.licenses)
        ) {
          const licenses = licenseAndCatalog.data.licenses;
          const hapoConfig = licenseAndCatalog.data.config;
          const dealType = licenseAndCatalog.data.deal_type ?? DEAL_TYPE_HAPO;

          setLicenseInfo({
            licenses: licenses.map((returnedSku: HapoSkuType) => ({
              ...returnedSku,
              sku_name: trimSKUName(returnedSku.sku_name),
              available_quantity: getLicenseAvailableQuantity(
                returnedSku.product_code,
                hapoConfig
              ),
            })),
            config: hapoConfig,
            dealType,
            dynamicOveragePercent: licenseAndCatalog.data.dynamic_overage_buffer_percent,
          });
        }
      } catch (e) {
        console.log(e);
      }
    };

    fetchLicencesData();
    CustomerPortalGoogleAnalyticsPageView('Enterprise Agreement Checkout');

    window.addEventListener('beforeunload', handleCloseAnnouncementModal);

    return () => {
      // Reset and close the announcement modal
      handleCloseAnnouncementModal();

      window.removeEventListener('beforeunload', handleCloseAnnouncementModal);
    };
  }, []);

  useEffect(() => {
    if (
      Array.isArray(licenseInfo.licenses) &&
      licenseInfo.licenses.length > 0
    ) {
      const checkForValidation = async () => {

        const isHapoConfigInsufficient = isCartInvalidAgainstConfig(
          state.hapoProducts,
          licenseInfo.config,
          licenseInfo.dealType,
        );
        const invalidSkus = isCartEmpty ? [] : await getInvalidSKUsFromCart(
          state.hapoProducts,
          state.companyId,
          accessToken,
        );
        setInsufficientHapoQuantities(isHapoConfigInsufficient);

        if (
          isCartEmpty ||
        isCartProductsInvalid(
          state.hapoProducts,
          licenseInfo.licenses,
          availableLicensesIds
        ) ||
        isHapoConfigInsufficient ||
        invalidSkus.length > 0
        ) {
          setRedirectToCatalog(true);
        } else {
          setRedirectToCatalog(false);
          setIsValid(validateFormSubmit(formData.version));
        }
      };

      checkForValidation();
    }
  }, [ state.hapoProducts, licenseInfo.licenses ]);

  const fetchFeatureFlags = async () => {
    setIsEntityManagementEnabled(
      await featureFlag.isEntityManagementEnabled(
        state.companyId,
        accessToken
      )
    );
    setIsDynamicOverageEnabled(
      await featureFlag.isDynamicOverageEnabled(
        state.companyId,
        accessToken
      )
    );
  };

  const fetchAllEntities = async () => {
    try {
      const entitiesResponse = await axiosGet<EntityDTO[]>(
        ENTITIES_URL,
        state.companyId,
        accessToken
      );
      setAllEntities(
        entitiesResponse.data.map((entity) => ({
          _id: entity._id,
          name: entity.name,
        }))
      );
    } catch (e) {
      console.log(`Unable to retrieve entities: ${e.message}`);
    }
  };

  const handleEmailsUpdate = (items: string[]) => {
    const newFormData = { ...formData };
    newFormData.informList = items;
    setFormData(newFormData);
  };

  const handleFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newFormData = { ...formData };
    newFormData[event.target.id] = event.target.value;
    setFormData(newFormData);
  };

  const getVersionTextFieldInput = () => {
    const {
      hasOnPremOrchLicenses,
      hasStandaloneLicenses,
      hasServiceLicenses,
      hasProcessMiningLicenses,
      hasCloudBundleLicenses,
      hasAutomationSuiteBundleLicenses,
      hasDedicatedCloudBundleLicenses,
    } = getLicensesTypesInCart(state.hapoProducts);

    const hasCloudBundleAndOnPremLicenses =
      (hasCloudBundleLicenses || hasDedicatedCloudBundleLicenses) &&
      (hasOnPremOrchLicenses ||
        hasStandaloneLicenses ||
        hasServiceLicenses ||
        hasProcessMiningLicenses ||
        hasAutomationSuiteBundleLicenses);

    /**
     * if no cloud bundle { show on prem }
     * if cloud and on prem { show on prem specific label }
     * else dont show
     */
    let versionLabelText;

    if (!hasCloudBundleLicenses && !hasDedicatedCloudBundleLicenses) {
      versionLabelText = t(
        'hapo_checkout_version_select_field_label',
        'Version'
      );
    } else if (hasCloudBundleAndOnPremLicenses) {
      versionLabelText = t(
        'hapo_checkout_version_select_field_onprem_label',
        'On-Prem Version'
      );
    }

    return versionLabelText;
  };

  const versionLabelText = getVersionTextFieldInput();

  /**
   * Call this method on each required event handler
   * Version is only required if has on-prem licenses
   * @param includesNewOrch
   */
  const validateFormSubmit = (version: any) => {
    const needsVersionSelected = versionLabelText ? true : false;
    const isVersionSelected: boolean = version ? true : false;

    if (
      licenseInfo.licenses.length > 0 &&
      isCartProductsInvalid(
        state.hapoProducts,
        licenseInfo.licenses,
        availableLicensesIds
      ) &&
      insufficientHapoQuantities
    ) {
      return false;
    } else if (needsVersionSelected && !isVersionSelected) {
      return false;
    }

    return true;
  };

  const handleVersionChange = (event: any) => {
    const selectedVersion = event?.value;

    const has60kInAutomationSuite = state.hapoProducts.some(product =>
      (product.type === AUTOMATION_SUITE || product.type === EXISTING_AUTOMATION_SUITE) &&
      product.products?.some(subProduct => subProduct.product_code === FLEX_AI_UNITS_BUNDLE_60K)
    );

    const selectedVersionIndex = LICENSE_VERSIONS.findIndex(v => v.value === selectedVersion);
    const version2022_10Index = LICENSE_VERSIONS.findIndex(v => v.value === '2022.10');

    if (selectedVersion && has60kInAutomationSuite && (selectedVersionIndex > version2022_10Index)) {
      setPendingVersion(event);
      setShowVersionWarningModal(true);
      return;
    }
    applyVersionChange(event);
  };

  const applyVersionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setIsValid(validateFormSubmit(event));
    const newFormData = { ...formData };
    newFormData.version = event;
    setFormData(newFormData);
  };

  const handleCloseVersionWarningModal = () => {
    setShowVersionWarningModal(false);
    setPendingVersion(null);
  };

  const handleConfirmVersionWarning = () => {
    if (pendingVersion) {
      applyVersionChange(pendingVersion);
    }
    handleCloseVersionWarningModal();
  };

  const handleFormSubmit = async () => {
    const elaWarningInfo = getELAWarningInfo(
      state.hapoProducts,
      licenseInfo.config,
      licenseInfo.dealType
    );

    // Spin up confirmation modal
    if (elaWarningInfo && elaWarningInfo.aboveCapGroups.length > 0) {
      const maxAllowedOveragePercent = licenseInfo.dynamicOveragePercent ?? 10;
      const maxEntitledUsage = 100 + maxAllowedOveragePercent;
      const m1 = isDynamicOverageEnabled ?
        t(
          'hapo_confirmation_message1_v2',
          'This license request will take your usage to more than {{ maxEntitledUsage }}% of your entitlement and will trigger a billing event for the entire overage above the entitlement.',
          { maxEntitledUsage }
        ) : t(
          'hapo_confirmation_message1',
          'This license request will take your usage to more than 110% of your entitlement and will trigger a billing event for the entire overage above the entitlement.'
        );
      const message =
        '<p>' + m1 + '</p><p>' +
        t(
          'hapo_confirmation_message2',
          'The license group(s) impacted include: {{ groupNames }}',
          { groupNames: elaWarningInfo.aboveCapGroups.join(', ') }
        ) +
        '</p><p>' +
        t(
          'hapo_confirmation_message3',
          'Please confirm that you would like to proceed with your request.'
        ) +
        '</p>';

      dispatch({
        type: HAPO_DISPLAY_ANNOUNCEMENT_MODAL,
        payload: {
          message,
          action: 'confirm',
        },
      });

      return;
    }

    await submitForm();
  };

  const submitForm = async () => {
    setSubmitButtonLoading(true);

    const body: any = {
      phone_number: formData.phoneNumber.replace(/\D/g, ''),
      version: formData.version?.value,
      inform_list: formData.informList.flat(),
      note: formData.notes,
      products: getDeepCopyofProductsForAPI(state.hapoProducts),
      company_id: state.companyId,
      company_name: state.companyName,
      ...(selectedEntity?._id && selectedEntity._id !== NONE_OPTION.value && { entity_id: selectedEntity._id }),
    };

    try {
      const response = await axios.post(
        `${BACKEND_HOST_NAME}/api/v1/hapo/order`,
        body,
        {
          headers: {
            [Header.AUTHORIZATION]: `Bearer ${accessToken}`,
            [Header.SELECTED_ACCOUNT_ID]: state.companyId,
            [Header.AUTH_TYPE]: authType,
          },
        }
      );

      if (response.status === 201) {
        handleSuccess();
        dispatch({
          type: HAPO_RESET_CART,
          payload: null,
        });
      }
    } catch (err) {
      handleError(err.response?.data?.message ?? err.message);
    } finally {
      setSubmitButtonLoading(false);
    }
  };

  const handleError = (errMessage: string) => {
    dispatch({
      type: 'setBannerType',
      payload: 'error',
    });
    dispatch({
      type: 'setBannerMsg',
      payload: t(
        'hapo_checkout_cart_error_msg',
        `There was an error in submitting your request. Please try again. ${errMessage}`,
        { errMessage }
      ),
    });
  };

  const handleSuccess = () => {
    setRedirectToCatalog(false);
    setRedirectToHapoDashboard(true);
    dispatch({
      type: 'setBannerType',
      payload: 'withActions',
    });
    dispatch({
      type: 'setBannerMsg',
      payload: t(
        'hapo_checkout_cart_success_msg',
        'Request is submitted. You will receive a confirmation email shortly. You can view the status in Past Requests.'
      ),
    });
    dispatch({
      type: 'setBannerActions',
      payload: [
        {
          name: t('hapo_checkout_cart_success_cta_1', 'View Status'),
          url: '/hapo/request-history',
        },
        {
          name: t('hapo_checkout_cart_success_cta_2', 'Dismiss'),
          url: '',
        },
      ],
    });
  };

  /**
   * If changing quantity of 2nd level child, must pass in parentOrchId
   * @param productId: The sku_id of the product (actual license)
   * @param parentOrchId: The generated _id of orch in products array in cart
   */
  const handleQuantityChange = (productId: string, parentOrchId?: string) => (
    event: any // React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    event.stopPropagation();
    const quantity = parseInt(event.target.value) || 0;

    if (parentOrchId) {
      dispatch({
        type: HAPO_UPDATE_2ND_LEVEL_LICENSE_QUANTITY,
        payload: {
          parent_id: parentOrchId,
          sku_id: productId,
          quantity,
        },
      });
    } else {
      dispatch({
        type: HAPO_UPDATE_1ST_LEVEL_LICENSE_QUANTITY,
        payload: {
          sku_id: productId,
          quantity,
        },
      });
    }
    event.target.value = quantity;
  };

  /**
   *
   * @param cartProductId: The generated _id of the product in cart
   * @param productId : The sku_id of the product (actual license)
   */
  const handleRemoveTopLevel = (cartProductId?: string, productId?: string) => (
    event: React.MouseEvent<HTMLParagraphElement>
  ) => {
    const payload = cartProductId
      ? { _id: cartProductId }
      : productId
        ? { sku_id: productId }
        : undefined;
    dispatch({
      type: HAPO_REMOVE_1ST_LEVEL_LICENSE,
      payload,
    });
  };

  /**
   * @param parentOrchId: The generated _id of orch
   * @param productId: The actual _id of license
   */
  const handleRemoveChildItem = (parentOrchId: string, productId: string) => (
    event: React.MouseEvent<HTMLParagraphElement>
  ) => {
    if (!parentOrchId || !productId) {
      return;
    }
    dispatch({
      type: HAPO_REMOVE_2ND_LEVEL_LICENSE,
      payload: {
        parent_id: parentOrchId,
        sku_id: productId,
      },
    });
  };

  const handleConfirmAnnouncementModal = async () => {
    handleCloseAnnouncementModal();
    await submitForm();
  };

  const handleCloseAnnouncementModal = () => {
    dispatch({
      type: HAPO_DISPLAY_ANNOUNCEMENT_MODAL,
      payload: {},
    });
  };

  const generateLicense = (
    productType: string,
    productSkuName: string,
    cartProductId: string | undefined,
    productQuantity: number,
    key: number,
    productId: string,
    parentOrchId: string | undefined,
    parentOrchIndex: number | undefined
  ) => {
    let classes = `${baseClass}__Cart-Product`;
    let quantityHelperText = undefined;
    const isStandalone = productType === STANDALONE;
    const isService = productType === SERVICE;
    const isCloud = productType === AUTOMATION_CLOUD;
    const isNewOrch = productType === NEW_ORCHESTRATOR;
    const isExistingOrch = productType === EXISTING_ORCHESTRATOR;
    const isNewProcessMining = productType === NEW_PROCESS_MINING;
    const isExistingProcessMining = productType === EXISTING_PROCESS_MINING;
    const isExistingCloud = productType === EXISTING_CLOUD;
    const isExistingAutomationSuite = productType === EXISTING_AUTOMATION_SUITE;
    const isAutomationSuite = productType === AUTOMATION_SUITE;
    const isExistingDedicatedAutomationCloud = productType === EXISTING_DEDICATED_AUTOMATION_CLOUD;
    const isParentBundle =
      isNewOrch ||
      isExistingOrch ||
      isNewProcessMining ||
      isExistingProcessMining ||
      isExistingCloud ||
      isExistingAutomationSuite ||
      isCloud ||
      isAutomationSuite ||
      isExistingDedicatedAutomationCloud;
    if (isStandalone) {
      classes += ` ${baseClass}__Cart-Product-Standalone`;
    } else if (isCloud || isExistingCloud || isExistingDedicatedAutomationCloud) {
      classes += ` ${baseClass}__Cart-Product-Cloud`;
    } else if (isNewOrch) {
      classes += ` ${baseClass}__Cart-Product-New-Orch`;
    } else if (isExistingOrch) {
      classes += ` ${baseClass}__Cart-Product-Existing-Orch`;
    } else if (isService) {
      classes += ` ${baseClass}__Cart-Product-Service`;
    } else if (isExistingAutomationSuite || isAutomationSuite) {
      classes += ` ${baseClass}__Cart-Product-Automation-Suite`;
    }

    const productName = (
      <h4
        className={`${baseClass}__Cart-Product-Title`}
        data-testid="HapoCheckout__productName"
      >
        {productSkuName}
      </h4>
    );

    const tooltipText: string = t(
      'hapo_checkout_cart_remove_item_tooltip_2',
      'You need at least one item in order to provision.'
    );

    let removeItem;
    if (state.hapoProducts.length === 1 && !state.hapoProducts[0].products) {
      removeItem = (
        <Tooltip
          title={tooltipText}
          placement="bottom">
          <p className={`${baseClass}__Cart-Product-Remove`}>Remove</p>
        </Tooltip>
      );
    } else if (isParentBundle) {
      removeItem = (
        <p
          onClick={handleRemoveChildItem(parentOrchId || '', productId || '')}
          data-child-id={cartProductId}
          data-parent-id={parentOrchId}
          className={`${baseClass}__Cart-Product-Remove`}
        >
          {t('hapo_checkout_cart_remove_item_label', 'Remove')}
        </p>
      );
    } else if (isStandalone || isService) {
      removeItem = (
        <p
          onClick={handleRemoveTopLevel(cartProductId, undefined)}
          className={`${baseClass}__Cart-Product-Remove`}
          id={productId}
        >
          {t('hapo_checkout_cart_remove_item_label', 'Remove')}
        </p>
      );
    }

    const rawLicense = licenseInfo.licenses.find(sku => sku._id === productId);

    if (rawLicense) {
      if (
        rawLicense.min_quantity &&
        productQuantity < rawLicense.min_quantity
      ) {
        quantityHelperText = t(
          'hapo_checkout_cart_required_min_quantity_helper',
          `A minimum of ${rawLicense.min_quantity} is required`,
          { minQuantity: rawLicense.min_quantity }
        );
      } else if (
        productQuantity >
        (rawLicense.max_quantity
          ? rawLicense.max_quantity
          : Number.MAX_SAFE_INTEGER)
      ) {
        quantityHelperText = t(
          'hapo_checkout_cart_max_quantity_helper',
          `The maximum limit of ${rawLicense.max_quantity} has been reached`,
          { maxQuantity: rawLicense.max_quantity }
        );
      } else if (productQuantity < 1) {
        quantityHelperText = t(
          'hapo_checkout_cart_enter_min_one_item_helper',
          'Please enter quantity of 1 or more'
        );
      }
    } else {
      quantityHelperText = t(
        'hapo_checkout_cart_product_not_in_catalog_helper',
        'This product no longer exists in the catalog. Please remove it from the cart to continue.'
      );
    }

    return (
      <div
        className={classes}
        key={key}>
        {(isStandalone || isService) && (
          <div className={`${baseClass}__Cart-Product-Standalone-Info`}>
            {productName}
          </div>
        )}

        {isParentBundle && productName}

        <div className={`${baseClass}__Cart-Quantity-Container`}>
          <>
            <TextField
              className={`${baseClass}__Cart-Product-Quantity`}
              variant={'outlined' as any}
              value={productQuantity}
              onChange={handleQuantityChange(productId!, parentOrchId)}
              onClick={e => e.stopPropagation()}
              onFocus={e => e.target.select()}
              error={false}
              required={false}
              InputLabelProps={{ shrink: true }}
              type="number"
              InputProps={{ className: 'Tall' }}
              inputProps={{
                'data-child-id': cartProductId,
                'data-parent-id': parentOrchId,
                'data-is-standalone': isStandalone || isService,
                'data-parent-index': parentOrchIndex,
                'data-child-index': key,
              }}
              id={cartProductId}
              data-testid="HapoCheckout__changeQuantity"
            />
            {removeItem}
          </>
          {quantityHelperText && (
            <p className={`${baseClass}__Cart-Product-HelperText`}>
              {quantityHelperText}
            </p>
          )}
        </div>
      </div>
    );
  };

  const generateCartPanelSubtitle = (
    _baseClass: string,
    subtitleText: string,
    children: JSX.Element[],
    displayDivider: boolean = false
  ) => (
    <>
      {displayDivider && (
        <div className={`${_baseClass}__Cart-Products-Divider`} />
      )}
      <div className={`${_baseClass}__Cart-Standalone-Products-Container`}>
        <p className={`${_baseClass}__Cart-Standalone-Container-Title`}>
          {subtitleText}
        </p>
        {children}
      </div>
    </>
  );

  const getNewBundleDisplayComponents = (
    newBundles: ReturnType<
      typeof getLicensesGroupedByType
    >[typeof NEW_ORCHESTRATOR],
    bundleType: string
  ) => {
    if (!Array.isArray(newBundles) || newBundles.length < 1) {
      return [];
    }
    const tooltipText: string = t(
      'hapo_checkout_cart_remove_item_tooltip_1',
      'Removing server will also remove the products for it.'
    );
    return newBundles.map(newBundle => (
      <React.Fragment key={newBundle._id}>
        <div className={`${baseClass}__Cart-Orchestrator-New`}>
          <h4 className={`${baseClass}__Cart-Product-Title`}>
            {newBundle.sku_name}
          </h4>
          <Tooltip
            title={tooltipText}
            placement="bottom">
            <p
              onClick={handleRemoveTopLevel(newBundle._id)}
              id={newBundle._id}
              data-orchestrator
              className={`${baseClass}__Cart-Product-Remove`}
            >
              {t('hapo_checkout_cart_remove_item_label', 'Remove')}
            </p>
          </Tooltip>
        </div>
        {newBundle.products && newBundle.products.length > 0 && (
          <div className={`${baseClass}__Cart-Product-Bundle-Container`}>
            {newBundle.products.map((orchProduct, i) => generateLicense(
              bundleType,
              orchProduct.sku_name,
              orchProduct._id,
              orchProduct.quantity,
              i,
              orchProduct.sku_id,
              newBundle._id,
              undefined
            ))}
          </div>
        )}
      </React.Fragment>
    ));
  };

  const getExistingBundleDisplayComponents = (
    existingBundles: ReturnType<
      typeof getLicensesGroupedByType
    >[typeof EXISTING_ORCHESTRATOR],
    bundleType: string
  ) => {
    if (!Array.isArray(existingBundles) || existingBundles.length < 1) {
      return [];
    }

    return existingBundles.map(existingBundle => (
      <React.Fragment key={existingBundle._id}>
        <div className={`${baseClass}__Cart-Orchestrator`}>
          <p className={`${baseClass}__Cart-Orchestrator-Title`}>
            {existingBundle.display_name ||
              existingBundle.existing_license_name}
          </p>
          <p className={`${baseClass}__Cart-Orchestrator-Code`}>
            {existingBundle.existing_license_code}
          </p>
          {!availableLicensesIds.includes(
            existingBundle.existing_license_code || ''
          ) && (
            <p className={`${baseClass}__Cart-Orchestrator-HelperText`}>
              {t(
                'hapo_checkout_cart_invalid_license_info_text',
                'This license is no longer active. Please remove products to continue.'
              )}
            </p>
          )}
        </div>
        {existingBundle.products && existingBundle.products.length > 0 && (
          <div className={`${baseClass}__Cart-Product-Bundle-Container`}>
            {existingBundle.products.map((orchProduct, i) => generateLicense(
              bundleType,
              orchProduct.sku_name,
              orchProduct._id,
              orchProduct.quantity,
              i,
              orchProduct.sku_id,
              existingBundle._id,
              undefined
            ))}
          </div>
        )}
      </React.Fragment>
    ));
  };

  const generateProducts = (
    groupedRes: { [key in ApiSkuType]: GlobalStoreState['rfqCart']['products'] },
    index: number
  ) => {
    const {
      hasOnPremOrchLicenses,
      hasStandaloneLicenses,
      hasServiceLicenses,
      hasProcessMiningLicenses,
      hasCloudBundleLicenses,
      hasAutomationSuiteBundleLicenses,
      hasDedicatedCloudBundleLicenses,
    } = getLicensesTypesInCart(_.flatten(Object.values(groupedRes)));
    const entityName = indexToEntityId[index] ? entityIdToEntity[indexToEntityId[index]].name : (
      selectedEntity && selectedEntity._id !== NONE_OPTION.value ? selectedEntity.name : 'Not assigned to any entity'
    );

    return (
      <div className={`${baseClass}__Cart-ContentWrapper--nonEmpty`}>
        <p className='entity-name'>
          {isEntityManagementEnabled ? entityName : ''}
        </p>
        {hasOnPremOrchLicenses &&
          generateCartPanelSubtitle(
            baseClass,
            RFQ.productLabelText.onPrem,
            getNewBundleDisplayComponents(
              groupedRes[NEW_ORCHESTRATOR],
              NEW_ORCHESTRATOR
            ).concat(
              getExistingBundleDisplayComponents(
                groupedRes[EXISTING_ORCHESTRATOR],
                EXISTING_ORCHESTRATOR
              )
            ),
            false
          )}
        {hasStandaloneLicenses &&
          generateCartPanelSubtitle(
            baseClass,
            RFQ.productLabelText.standalone,
            groupedRes.Standalone?.map((skuProduct, i) =>
              generateLicense(
                skuProduct.type,
                skuProduct.sku_name!,
                skuProduct._id,
                skuProduct.quantity!,
                i,
                skuProduct.sku_id!,
                undefined,
                undefined
              )
            ),
            hasOnPremOrchLicenses
          )}
        {hasServiceLicenses &&
          generateCartPanelSubtitle(
            baseClass,
            RFQ.productLabelText.services,
            groupedRes.Service?.map((skuProduct, i) =>
              generateLicense(
                skuProduct.type,
                skuProduct.sku_name!,
                skuProduct._id,
                skuProduct.quantity!,
                i,
                skuProduct.sku_id!,
                undefined,
                undefined
              )
            ),
            hasOnPremOrchLicenses || hasStandaloneLicenses
          )}
        {hasProcessMiningLicenses &&
          generateCartPanelSubtitle(
            baseClass,
            RFQ.productLabelText.processMining,
            getNewBundleDisplayComponents(
              groupedRes[NEW_PROCESS_MINING],
              NEW_PROCESS_MINING
            ).concat(
              getExistingBundleDisplayComponents(
                groupedRes[EXISTING_PROCESS_MINING],
                EXISTING_PROCESS_MINING
              )
            ),
            hasOnPremOrchLicenses ||
              hasStandaloneLicenses ||
              hasServiceLicenses
          )}
        {hasAutomationSuiteBundleLicenses &&
          generateCartPanelSubtitle(
            baseClass,
            RFQ.productLabelText.automationSuite,
            getNewBundleDisplayComponents(
              groupedRes[AUTOMATION_SUITE],
              AUTOMATION_SUITE
            ).concat(
              getExistingBundleDisplayComponents(
                groupedRes[EXISTING_AUTOMATION_SUITE],
                EXISTING_AUTOMATION_SUITE
              )),
            hasOnPremOrchLicenses ||
              hasStandaloneLicenses ||
              hasServiceLicenses ||
              hasProcessMiningLicenses
          )}
        {hasCloudBundleLicenses &&
          generateCartPanelSubtitle(
            baseClass,
            RFQ.productLabelText.cloud,
            getNewBundleDisplayComponents(
              groupedRes[CLOUD],
              AUTOMATION_CLOUD
            ).concat(
              getExistingBundleDisplayComponents(
                groupedRes[EXISTING_CLOUD],
                EXISTING_CLOUD
              )),
            hasOnPremOrchLicenses ||
              hasStandaloneLicenses ||
              hasServiceLicenses ||
              hasProcessMiningLicenses ||
              hasAutomationSuiteBundleLicenses
          )}
        {hasDedicatedCloudBundleLicenses &&
          generateCartPanelSubtitle(
            baseClass,
            RFQ.productLabelText.dedicatedCloud,
            getExistingBundleDisplayComponents(
              groupedRes[EXISTING_DEDICATED_AUTOMATION_CLOUD],
              EXISTING_DEDICATED_AUTOMATION_CLOUD
            ),
            hasOnPremOrchLicenses ||
            hasStandaloneLicenses ||
            hasServiceLicenses ||
            hasProcessMiningLicenses ||
            hasAutomationSuiteBundleLicenses ||
            hasCloudBundleLicenses
          )}
      </div>
    );
  };

  // Group Licenses
  const licensesGroupedByType = getLicensesGroupedByType(state.hapoProducts);
  const licenseCodeToEntity: Record<string, Record<string, string>> = {};
  const entityIdToEntity: Record<string, Record<string, string>> = {};
  (state.companyLicenses?.licenses ?? []).forEach((license) => {
    if (license.entity?.name) {
      licenseCodeToEntity[license.boon_license_cd] = license.entity;
      entityIdToEntity[license.entity._id] = license.entity;
    }
  });
  let entityIdToIndex: Record<string, number> = {};
  let indexToEntityId: Record<number, string> = {};

  const groupedEntriesByEntity: Array<Array< { [key in ApiSkuType]: GlobalStoreState['rfqCart']['products'] }>> = [ [] ];
  Object.keys(licensesGroupedByType).forEach((key) => {
    (licensesGroupedByType as any)[key].forEach((entry: any) => {
      const entityId = licenseCodeToEntity[entry?.existing_license_code]?._id;
      if (!entityId) {
        groupedEntriesByEntity[0].push(entry);
        return;
      }

      let index = entityIdToIndex[entityId];
      if (!index) {
        groupedEntriesByEntity.push([]);
        index = groupedEntriesByEntity.length - 1;
        entityIdToIndex[entityId] = index;
        indexToEntityId[index] = entityId;
      }
      groupedEntriesByEntity[index].push(entry);
    });
  });

  entityIdToIndex = {};
  indexToEntityId = {};

  const sortedGroupedEntriesByEntity: Array< { [key in ApiSkuType]: GlobalStoreState['rfqCart']['products'] }> = [
    ...groupedEntriesByEntity.slice(1).sort((a, b) => {
      const A = licenseCodeToEntity[(a[0] as any)?.existing_license_code].name;
      const B = licenseCodeToEntity[(b[0] as any)?.existing_license_code].name;
      return A.localeCompare(B);
    }),
    groupedEntriesByEntity[0],
  ].map((group, index) => {
    const sortedGroup: { [key in ApiSkuType]: GlobalStoreState['rfqCart']['products'] } = {
      [NEW_ORCHESTRATOR]: [],
      [EXISTING_ORCHESTRATOR]: [],
      [CLOUD]: [],
      [STANDALONE]: [],
      [SERVICE]: [],
      [NEW_PROCESS_MINING]: [],
      [EXISTING_PROCESS_MINING]: [],
      [EXISTING_CLOUD]: [],
      [AUTOMATION_SUITE]: [],
      [EXISTING_AUTOMATION_SUITE]: [],
      [EXISTING_DEDICATED_AUTOMATION_CLOUD]: [],
    };
    group.forEach((entry: any) => {
      sortedGroup[entry.type as ApiSkuType].push(entry);
    });

    // Update entityIdToIndex and indexToEntityId
    const entityId = licenseCodeToEntity[(group[0] as any)?.existing_license_code]?._id;
    if (entityId) {
      entityIdToIndex[entityId] = index;
      indexToEntityId[index] = entityId;
    }

    return sortedGroup;
  });

  if (redirectToCatalog) {
    return <Redirect to="/hapo" />;
  }

  if (redirectToHapoDashboard) {
    return <Redirect to="/company?section=hapo" />;
  }

  /* Return if no company id selected */
  if (state.companyId === '') {
    return (
      <>
        <Helmet>
          <title>
            {state.isUtoEnabled
              ? t(
                'uto_checkout_page_title',
                'UiPath Tailored Offering Checkout | Customer Portal'
              )
              : t(
                'hapo_checkout_page_title',
                'Enterprise Agreement Checkout | Customer Portal'
              )}
          </title>
        </Helmet>
        <NoCompanyId />
      </>
    );
  } else if (!isEditHAPOLicenseAllowed) {
    return <Redirect to="/unauthorized" />;
  }

  return (
    <>
      <Helmet>
        <title>
          {state.isUtoEnabled
            ? t(
              'uto_checkout_page_title',
              'UiPath Tailored Offering Checkout | Customer Portal'
            )
            : t(
              'hapo_checkout_page_title',
              'Enterprise Agreement Checkout | Customer Portal'
            )}
        </title>
      </Helmet>
      <styles.CheckoutContainer>
        <styles.CheckoutForm>
          <div
            className={`${baseClass}__Form-Fields`}
            data-testid="HapoCheckout__form"
          >
            <div className={`${baseClass}__Form-Fields-Container`}>
              <FormControl className={`${baseClass}__Form-Field`}>
                <TextLabel
                  label={t(
                    'hapo_checkout_account_name_text_field_label',
                    'Business/Account Name'
                  )}
                />
                <TextField
                  variant={'outlined' as any}
                  placeholder={state.companyName}
                  error={false}
                  disabled
                  required={false}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ className: 'Tall' }}
                />
              </FormControl>

              <FormControl className={`${baseClass}__Form-Field`}>
                <TextLabel
                  label={t(
                    'hapo_checkout_requester_text_field_label',
                    'Requester'
                  )}
                />
                <TextField
                  variant={'outlined' as any}
                  placeholder={state.userName}
                  error={false}
                  disabled
                  required={false}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ className: 'Tall' }}
                />
              </FormControl>

              <FormControl className={`${baseClass}__Form-Field`}>
                <TextLabel
                  label={t(
                    'hapo_checkout_phone_number_text_field_label',
                    'Requester Phone Number'
                  )}
                />
                <TextField
                  variant={'outlined' as any}
                  value={formData.phoneNumber}
                  onChange={handleFieldChange}
                  error={false}
                  required={false}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ className: 'Tall' }}
                  id="phoneNumber"
                  data-testid="HapoCheckout__phoneTxtInput"
                />
              </FormControl>

              <FormControl className={`${baseClass}__Form-Field`}>
                <TextLabel
                  label={t(
                    'hapo_checkout_email_text_field_label',
                    'Requester Email'
                  )}
                />
                <TextField
                  variant={'outlined' as any}
                  placeholder={state.userEmail}
                  error={false}
                  disabled
                  required={false}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ className: 'Tall' }}
                />
              </FormControl>
            </div>

            {/* Version */}
            {versionLabelText && (
              <SelectWithSearch
                value={formData.version}
                className={`${baseClass}__Form-Field--Full Custom-Select--Outlined`}
                onChange={handleVersionChange}
                options={LICENSE_VERSIONS}
                placeholder={t(
                  'hapo_checkout_version_select_field_placeholder',
                  'Select version...'
                )}
                id="version"
                name="version"
                searchable
                label={versionLabelText}
                required
              />
            )}

            {isEntityManagementEnabled && (
              <SelectWithSearch
                className={`${baseClass}__Form-Field--Full Custom-Select--Outlined`}
                id='entity'
                name='entity'
                options={[
                  ...(selectedEntity ? [ NONE_OPTION ] : []),
                  ...allEntities
                    .map((entity) => ({
                      label: entity.name,
                      value: entity._id,
                    })),
                ]}
                value={!selectedEntity ? undefined : {
                  label: selectedEntity?.name,
                  value: selectedEntity?._id,
                }}
                onChange={(option: { label: string; value: string }) => {
                  setSelectedEntity({
                    _id: option.value,
                    name: option.label,
                  });
                }}
                searchable
                placeholder={
                  <div data-testid="filter-by-entity">
                    {t('no_entity_selected', 'No entity selected')}
                  </div>
                }
                label={t(
                  'hapo_checkout_entity_select_field_label',
                  'Apply entity to new licenses'
                )}
              />
            )}

            <TextLabel
              label={t(
                'hapo_checkout_inform_emails_text_field_label',
                'Whom else should be informed about your license request within your organization?'
              )}
            />
            <MultiEmailInput
              inputClass={`${baseClass}__Form-Field--Full`}
              containerClass={`${baseClass}__Multi-Email-Field`}
              updateItems={handleEmailsUpdate}
            />

            <TextLabel label={t('hapo_checkout_notes_text_field_label', 'Notes')} />
            <TextareaAutosize
              className={`${baseClass}__Form-Field--Full ${baseClass}__Form-Field--Notes`}
              value={formData.notes}
              onChange={handleFieldChange}
              required={false}
              id="notes"
              minRows={5}
              maxLength={2000}
            />

            <Button
              className={`${baseClass}__Form-Button`}
              isLoading={submitButtonLoading}
              disabled={!isValid}
              isValid={isValid}
              onClick={handleFormSubmit}
              text={t(
                'hapo_checkout_provision_licenses_btn',
                'Provision Licenses'
              )}
            />
            <p className={`${baseClass}__Form-Text`}>
              {t(
                'hapo_checkout_disclaimer_text',
                'By clicking the "Provision Licenses" button, a request to provision those licenses will be sent to your UiPath team. You will receive a first email confirming your request. In maximum one working day, you will receive a second email that will confirm that the licenses are provisioned together with certificates and the installation instructions.'
              )}
            </p>
          </div>
        </styles.CheckoutForm>
        <styles.CheckoutCart>
          {licenseInfo.licenses.length > 0 && (
            <>
              <div
                className={`${baseClass}__Cart-Inner`}
                data-testid="HapoCheckout__cart"
              >
                <h3 className={`${baseClass}__Cart-Title`}>
                  {t('hapo_checkout_cart_title', 'Cart')}
                </h3>
                {state.hapoProducts.length > 0 && (
                  <div className='divider' />
                )}
                {sortedGroupedEntriesByEntity.map((groupedRes, i) => (
                  <React.Fragment key={i}>
                    {generateProducts(groupedRes, i)}
                    {i < sortedGroupedEntriesByEntity.length - 1 && (
                      <div className='divider' />
                    )}
                  </React.Fragment>
                ))}
                {!isCartEmpty && (
                  <div className='divider' />
                )}
              </div>
            </>
          )}
        </styles.CheckoutCart>
      </styles.CheckoutContainer>
      <ConfirmationModal
        modalHeading={t('confirmation', 'Confirmation')}
        primaryButtonText={t('confirm', 'Confirm')}
        secondaryButtontext={t('cancel', 'Cancel')}
        message={confirmationModalMessage || ''}
        open={openConfirmationModal}
        handleConfirm={handleConfirmAnnouncementModal}
        handleClose={handleCloseAnnouncementModal}
      />
      <ConfirmationModal
        modalHeading={t('warning', 'Warning')}
        primaryButtonText={t('confirm', 'Confirm')}
        secondaryButtontext={t('cancel', 'Cancel')}
        message={t(
          'hapo_checkout_version_warning_message',
          'Please note that AI Units are not supported for versions prior to 22.10. Document understanding units will be provisioned in this'
        )}
        open={showVersionWarningModal}
        handleConfirm={handleConfirmVersionWarning}
        handleClose={handleCloseVersionWarningModal}
      />
    </>
  );
};

export default HapoCheckoutPage;

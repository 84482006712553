import type {
  FacetValue,
  Raw,
  SearchEngine,
} from '@coveo/headless';
import {
  buildSearchEngine,
  loadClickAnalyticsActions,
  loadContextActions,
} from '@coveo/headless';
import {
  Header,
  Locale,
} from '@customer-portal/constants';

import { localeToLanguage } from '../constants/localization.constants';
import type { AnalyticsActionsPayload } from '../constants/search.constants';
import {
  DOCS_VERSION_SKIP_TAG_NAME,
  LOCALE_TO_SUPPORTED_SEARCH_LOCALE_MAP,
  QUERY_TEXT_URL_KEY,
  SEARCH_HUB_NAME,
  SEARCH_SOURCE,
  SearchAnalyticsActions,
  SearchResultSource,
} from '../constants/search.constants';
import type { FacetNode } from '../pages/Search/Facets/FacetFilter/FacetFilter';
import { FacetFilterTypes } from '../pages/Search/Facets/types';
import { getCloudPrefixedPath } from './cloud';
import CoveoUtil from './coveo';

type PropType<TObj, TProp extends keyof TObj> = TObj[TProp];

export const headlessSearchEngine = (() => {
  let origin: string | undefined;

  try {
    if (process.env.REACT_APP_SEARCH_PUBLIC_ORIGIN) {
      origin = new URL(process.env.REACT_APP_SEARCH_PUBLIC_ORIGIN).origin;
    } else {
      throw new Error('Missing environment variable for search');
    }
  } catch (e) {
    console.error(e);
  }

  const coveoEngine = buildSearchEngine({
    configuration: {
      // organization id and access token will be replaced by relay service
      organizationId: '-',
      accessToken: '-',
      platformUrl: origin,
      preprocessRequest: (req, clientOrigin) => {
        const newHeaders: Headers = new Headers(req.headers);
        // delete authorization header
        newHeaders.delete(Header.AUTHORIZATION);
        req.headers = newHeaders;
        return req;
      },
      search: { searchHub: SEARCH_HUB_NAME },
      analytics: {
        analyticsClientMiddleware: (_eventType, payload) => {
          const {
            visitorId, sessionId, isUipathUser,
          } = CoveoUtil.getSessionData();
          payload.visitorId = visitorId;
          payload.customData = {
            ...payload.customData,
            sessionid: sessionId,
            is_internal_user: isUipathUser,
          };
          return payload;
        },
        enabled: true,
        originLevel3: origin,
      },
    },
  });

  // add context values for engine
  const { setContext } = loadContextActions(coveoEngine);
  const setContextAction = setContext({
    source: SEARCH_SOURCE,
    // TODO: add Guest user check when the logic is implemented
    userState: 'Registered',
  });

  coveoEngine.dispatch(setContextAction);
  return coveoEngine;
})();

export const logSearchAnalyticsEvent = <K extends SearchAnalyticsActions>(
  engine: SearchEngine,
  eventType: SearchAnalyticsActions,
  payload: PropType<AnalyticsActionsPayload, K>
) => {
  const { logDocumentOpen } = loadClickAnalyticsActions(engine);

  if (eventType === SearchAnalyticsActions.DOCUMENT_OPEN) {
    engine.dispatch(logDocumentOpen(payload));
  }
};

export const pushNewHashToUrl = (newHash: string) => {
  if (typeof window !== 'undefined') {
    const valueWithHash =
      newHash.length > 0 && newHash.startsWith('#') ? newHash : `#${newHash}`;

    window.location.replace(valueWithHash);
  }
};

/**
 *
 * @param facetValue raw facet value string
 * @param facetType the facet type
 * @returns processed facet value string to be used as a label.
 * Note that for content types, we further apply localization when rendering the component
 */
export const processFacetValue = (
  facetValue: string,
  facetType: FacetFilterTypes,
  rawQueryValues?: Raw,
) => {
  if (
    facetType === FacetFilterTypes.VERSION &&
    rawQueryValues &&
    isDocsSource(rawQueryValues[FacetFilterTypes.CONTENT_TYPE] ?? '')
  ) {
    facetValue = facetValue.split('|').every((val: string, index: number) => index === 0 ? !isNaN(+val) && +val < 10 : !isNaN(+val)) ? DOCS_VERSION_SKIP_TAG_NAME : facetValue.split('|').join('.');
  } else if (facetType === FacetFilterTypes.VERSION) {
    facetValue = facetValue.split('|').join('.');
  } else if (
    facetType === FacetFilterTypes.CONTENT_TYPE &&
    isDocsSource(facetValue)
  ) {
    facetValue = SearchResultSource.DOCS;
  }

  return facetValue;
};

export const isDocsSource = (sourceName: string) => {
  const docsPortalRegex = new RegExp(`^${SearchResultSource.DOCS.toLowerCase()}`);
  return docsPortalRegex.test(sourceName.toLowerCase());
};

export const isDocsSourceActive = () => {
  if (typeof window !== 'undefined') {
    const searchParams = new URLSearchParams(
      window.location.hash.slice(1)
    );

    const selectedSources = searchParams.get(`f-${FacetFilterTypes.CONTENT_TYPE}`);
    return !selectedSources || selectedSources.split(',').some(isDocsSource);
  }

  return false;
};

export const getAllDocsSources = (nodes: FacetNode[]) => nodes.map((node) => node.value?.value ?? '')
  .filter(isDocsSource);

export const getAllDocsVersions = (nodes: FacetNode[]) => {
  const versions: string[] = [];

  const recurse = (node: FacetNode) => {
    versions.push(node.value?.value ?? '');
    if (node.childFacetNodes) {
      Object.values(node.childFacetNodes)
        .forEach(recurse);
    }
  };

  nodes.forEach((node) => {
    if (Array.from(Array(10).keys()).map(e => `${e}`)
      .includes(node.value?.value ?? '')) {
      recurse(node);
    }
  });

  return versions;
};

/**
 * @param values list of delivery option values
 * @returns record with key=single delivery option and
 * value={total number of results that includes the single delivery option ,
 *        list of multiple delivery options that contains the single delivery option}
 */
export const getGroupedDeliveryOptions = (values: FacetValue[]) => {
  const groupedOptions: Record<string, {
    numberOfResults: number;
    allValuesForGroup: string[];
  }> = {};

  values.forEach((value) => {
    const deliveryOptionValue: string = value.value ?? '';
    const facetNumberOfResults: number = value.numberOfResults ?? 0;
    const deliveryOptions = deliveryOptionValue.split(';');
    deliveryOptions.forEach((option) => {
      groupedOptions[option] = {
        numberOfResults: (groupedOptions[option]?.numberOfResults ?? 0) + facetNumberOfResults,
        allValuesForGroup: [ ...groupedOptions[option]?.allValuesForGroup ?? [], deliveryOptionValue ],
      };
    });
  });

  return groupedOptions;
};

/**
 * @param values list of delivery option values
 * @returns list of single delivery option values
 */
export const getDeliveryOptionsLists = (values: FacetValue[]) => {
  const groupedDeliveryOptions = getGroupedDeliveryOptions(values);
  const searchParams = new URLSearchParams(window.location.hash.slice(1));
  const allSelectedDeliveryOptions = new Set(searchParams.get(`f-${FacetFilterTypes.DELIVERY_OPTION}`)?.split(','));
  const deliveryOptionValues: FacetValue[] = [];

  Object.entries(groupedDeliveryOptions).forEach(([ name, { numberOfResults } ]) => {
    deliveryOptionValues.push({
      value: name,
      state: allSelectedDeliveryOptions.has(name) ? 'selected' : 'idle',
      numberOfResults,
    });
  });
  return deliveryOptionValues;
};

/**
 * @param nodes list of facet nodes
 * @returns list of facet values
 */
export const getFacetValues = (nodes: FacetNode[]) => {
  const facetValues: FacetValue[] = nodes.map(({ value }) => value ?? {
    value: '',
    state: 'idle',
    numberOfResults: 0,
  });
  return facetValues;
};

/**
 * Converts a UI locale to its corresponding supported search language.
 * Uses LOCALE_TO_SUPPORTED_SEARCH_LOCALE_MAP to determine the appropriate search locale,
 * then converts it to the actual language code using localeToLanguage map.
 *
 * @param locale - The current UI locale
 * @returns The supported search language code, falling back to English if not found
 *
 * Examples:
 * - getSupportedSearchLanguage(Locale.ko) → 'en'    // Korean UI → English search
 * - getSupportedSearchLanguage(Locale.ja) → 'ja'    // Japanese UI → Japanese search
 * - getSupportedSearchLanguage(Locale['pt-BR']) → 'pt'  // Brazilian Portuguese → Portuguese search
 */
export const getSupportedSearchLanguage = (locale: Locale) => {
  // For unsoppported locale for search(Russian, Turkish, Korean, Taiwan-Chinese)  -> return english language as a fallback
  // locale whose exact translation is not supported for search (Spanish-Mexico, Portuguese-Brazil) -> return the closest supported locale
  const resolvedLocale = LOCALE_TO_SUPPORTED_SEARCH_LOCALE_MAP[locale];

  // get language from localeToLanguage map, if not found, use English as a fallback
  return localeToLanguage.get(resolvedLocale) ?? localeToLanguage.get(Locale.en);
};

// Builds the search URL with the search query and the language parameter
// This is used to redirect the user to a search results page while replacing the current URL in browser history
export const getSearchRedirectUrl = ({
  text,
  locale,
}: {
  text: string;
  locale: Locale;
}) => {
  // Use English as a fallback if the supported language is undefined
  const supportedLanguage = getSupportedSearchLanguage(locale) ?? localeToLanguage.get(Locale.en);

  // The search URL is constructed with the search query and the language parameter
  const searchFragment = `${QUERY_TEXT_URL_KEY}=${text}&f-${FacetFilterTypes.LANGUAGE}=${supportedLanguage}`;
  return getCloudPrefixedPath(`/search#${searchFragment}`);
};

import React, {
  useEffect,
  useState,
} from 'react';
// Translations
import { useTranslation } from 'react-i18next';

// Styles
import * as styles from '../../../assets/css/RequestForQuote/BrowseProducts/RequestForQuoteBrowseOrchestratorProducts';
// Constants
import RFQ from '../../../constants/requestForQuote.constants';
import type { GlobalStoreState } from '../../../interfaces/contextStore';
import type { HapoSkuType } from '../../../types/hapo.types';
import type { SkuType } from '../../../types/requestForQuote.types';
import { debounce } from '../../../utils';
// Components
import Tabs from './CustomerPortal-RequestForQuote-BrowseProducts-Tabs';
import LicenseDetailPopUp from './CustomerPortal-RequestForQuote-LicenseDetailPopUp';
import Licenses from './CustomerPortal-RequestForQuote-Licenses';

const RequestForQuoteBrowseLicenseContainer = ({
  licenses = [],
  selectedCategory,
  lastSelectedOrchestrator,
  cartProducts,
  isAddToCartAllowed,
  requiresAutomationCloudBundle = false,
  requiresAutomationSuiteBundle = false,
  handleQuantityUpdate,
  handleRemoveLicenseFromCart,
  handleAddLicenseToCart,
  activeBoonLicenses = [],
}: {
  licenses: Array<SkuType | HapoSkuType>;
  selectedCategory: GlobalStoreState['rfqCart']['selectedCategory'];
  lastSelectedOrchestrator: GlobalStoreState['rfqCart']['lastSelectedOrchestrator'];
  cartProducts: GlobalStoreState['rfqCart']['products'] | GlobalStoreState['hapoProducts'];
  isAddToCartAllowed: boolean;
  requiresAutomationCloudBundle: boolean;
  requiresAutomationSuiteBundle: boolean;
  handleQuantityUpdate: (
    licenseId: string,
    newQuantity: number,
    productType: string,
    dispatch: any,
    lastSelectedOrchestrator?: GlobalStoreState['rfqCart']['lastSelectedOrchestrator']
  ) => void;
  handleRemoveLicenseFromCart: (
    licenseId: string,
    productType: string,
    dispatch: any,
    lastSelectedOrchestrator?: GlobalStoreState['rfqCart']['lastSelectedOrchestrator']
  ) => void;
  handleAddLicenseToCart: (
    license: {
      [key: string]: any;
      _id: string;
      sku_name: string;
      min_quantity: number;
      product_code: string;
    },
    productType: string,
    dispatch: any,
    lastSelectedOrchestrator?: GlobalStoreState['rfqCart']['lastSelectedOrchestrator'],
    ignoreConfirmationModal?: boolean,
    activeBoonLicenses?: any[]
  ) => void;
  activeBoonLicenses?: any[];
}) => {
  const { t } = useTranslation('common');
  // State
  const [ windowDimension, setWindowDimensions ] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [ searchInputText, setSearchInputText ] = useState('');
  const [ showPopUp, setShowPopUp ] = useState(false);
  const [ popUpData, setPopUpData ] = useState({
    _id: '',
    name: '',
    description: '',
    short_description: '',
    product_code: '',
    quantity: 0,
    min_quantity: 0,
  });
  const [ productCategory, setProductCategory ] = useState<string>(
    RFQ.onPremProductCategory.all.fallbackText
  );

  useEffect(() => {
    // On Change of selectedCategory, switch to all
    if (selectedCategory) {
      setProductCategory(RFQ.onPremProductCategory.all.fallbackText);
    }
  }, [ selectedCategory ]);

  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setWindowDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }, 1000);

    window.addEventListener('resize', debouncedHandleResize);
    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  });

  // Methods

  const handleSearchInput = (e: any) => {
    setSearchInputText(e.target.value);
  };

  const handleClearSearch = () => {
    setSearchInputText('');
  };

  const getLicensesTags = (licenses: any) => {
    const tags: any = [];
    let uniqueTags: any = [];

    if (licenses && licenses.length > 0) {
      licenses.forEach((item: any) => tags.push(item.tag));
      uniqueTags = tags.filter(function(elem: any, index: any, self: any) {
        return index === self.indexOf(elem);
      });
    }

    return uniqueTags;
  };

  // Get Existing licenses tags
  const licensesTags = getLicensesTags(licenses);

  const handleTabChange = (tag: string, e: any) => {
    setProductCategory(tag);
  };

  const handleShowPopUp = (data: any, e: any) => {
    if (e.target.getAttribute('data-type') === 'showPopUp') {
      setShowPopUp(data.showPopUp);
      setPopUpData(data.popUpContent);
    }
  };

  const handleClosePopUp = () => {
    setShowPopUp(false);
  };

  return (
    <styles.OrchestratorProducts data-testid="RequestForQuote__BrowseProducts__LicenseContainer">
      <h3 className="RequestForQuote__BrowseProducts__Title">
        {t('rfq_hapo_browse_products_title', 'Browse Products')}
      </h3>

      {/* Search */}
      <div className="RequestForQuote__BrowseProducts__Search">
        <svg
          width="11"
          height="11"
          viewBox="0 0 11 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.69141 6.41016C3.20182 6.92057 3.82161 7.17578 4.55078 7.17578C5.27995 7.17578 5.89974 6.92057 6.41016 6.41016C6.92057 5.89974 7.17578 5.27995 7.17578 4.55078C7.17578 3.82161 6.92057 3.20182 6.41016 2.69141C5.89974 2.18099 5.27995 1.92578 4.55078 1.92578C3.82161 1.92578 3.20182 2.18099 2.69141 2.69141C2.18099 3.20182 1.92578 3.82161 1.92578 4.55078C1.92578 5.27995 2.18099 5.89974 2.69141 6.41016ZM8.05078 7.17578L10.9492 10.0742L10.0742 10.9492L7.17578 8.05078V7.58594L7.01172 7.42188C6.31901 8.02344 5.4987 8.32422 4.55078 8.32422C3.49349 8.32422 2.59115 7.95964 1.84375 7.23047C1.11458 6.5013 0.75 5.60807 0.75 4.55078C0.75 3.49349 1.11458 2.60026 1.84375 1.87109C2.59115 1.1237 3.49349 0.75 4.55078 0.75C5.60807 0.75 6.5013 1.1237 7.23047 1.87109C7.95964 2.60026 8.32422 3.49349 8.32422 4.55078C8.32422 5.4987 8.02344 6.31901 7.42188 7.01172L7.58594 7.17578H8.05078Z"
            fill="#526069"
          />
        </svg>

        <input
          autoFocus
          type="text"
          name="search"
          placeholder={t(
            'rfq_hapo_browse_products_search_placeholder',
            'Search...'
          )}
          value={searchInputText}
          onChange={handleSearchInput}
        />

        <svg
          width="10"
          height="10"
          viewBox="0 0 10 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={`RequestForQuote__BrowseProducts__clearSearch ${
            searchInputText !== ''
              ? 'RequestForQuote__BrowseProducts__clearSearch--active'
              : ''
          }`}
          onClick={handleClearSearch}
        >
          <path
            d="M9.66659 1.27337L8.72659 0.333374L4.99992 4.06004L1.27325 0.333374L0.333252 1.27337L4.05992 5.00004L0.333252 8.72671L1.27325 9.66671L4.99992 5.94004L8.72659 9.66671L9.66659 8.72671L5.93992 5.00004L9.66659 1.27337Z"
            fill="#526069"
          />
        </svg>
      </div>
      {/* End search */}

      {/* Tabs */}
      <Tabs
        productCategory={productCategory}
        searchText={searchInputText}
        licenses={licenses}
        licensesTags={licensesTags}
        handleTabChange={handleTabChange}
      />
      {/* End Tabs */}

      {/* Product List */}
      <div className="RequestForQuote__BrowseProducts__List">
        {/* Robots */}
        {licensesTags.length > 0 &&
          licensesTags.includes(RFQ.productTags.robots.fallbackText) &&
          (productCategory === RFQ.onPremProductCategory.all.fallbackText ||
            productCategory ===
              RFQ.onPremProductCategory.robots.fallbackText) && (
          <>
            <div
              className="RequestForQuote__BrowseProducts__ProductCategory"
            >
              <p>
                {t(
                  RFQ.onPremProductCategory.robots.keyText,
                  RFQ.onPremProductCategory.robots.fallbackText
                )}
              </p>
            </div>

            <Licenses
              windowDimension={windowDimension}
              searchText={searchInputText}
              productCategory={RFQ.onPremProductCategory.robots}
              productTag={RFQ.productTags.robots}
              cloudLicenses={licenses}
              handleShowPopUp={handleShowPopUp}
              selectedCategory={selectedCategory}
              lastSelectedOrchestrator={lastSelectedOrchestrator}
              cartProducts={cartProducts}
              isAddToCartAllowed={isAddToCartAllowed}
              requiresAutomationCloudBundle={requiresAutomationCloudBundle}
              requiresAutomationSuiteBundle={requiresAutomationSuiteBundle}
              handleQuantityUpdate={handleQuantityUpdate}
              handleRemoveLicenseFromCart={handleRemoveLicenseFromCart}
              handleAddLicenseToCart={handleAddLicenseToCart}
              activeBoonLicenses={activeBoonLicenses}
            />
          </>
        )}

        {/* Users */}
        {licensesTags.includes(RFQ.productTags.users.fallbackText) &&
          (productCategory === RFQ.onPremProductCategory.all.fallbackText ||
            productCategory ===
              RFQ.onPremProductCategory.users.fallbackText) && (
          <>
            <div
              className="RequestForQuote__BrowseProducts__ProductCategory"
            >
              <p>
                {t(
                  RFQ.onPremProductCategory.users.keyText,
                  RFQ.onPremProductCategory.users.fallbackText
                )}
              </p>
            </div>

            <Licenses
              windowDimension={windowDimension}
              searchText={searchInputText}
              productCategory={RFQ.onPremProductCategory.users}
              productTag={RFQ.productTags.users}
              cloudLicenses={licenses}
              handleShowPopUp={handleShowPopUp}
              selectedCategory={selectedCategory}
              lastSelectedOrchestrator={lastSelectedOrchestrator}
              cartProducts={cartProducts}
              isAddToCartAllowed={isAddToCartAllowed}
              requiresAutomationCloudBundle={requiresAutomationCloudBundle}
              requiresAutomationSuiteBundle={requiresAutomationSuiteBundle}
              handleQuantityUpdate={handleQuantityUpdate}
              handleRemoveLicenseFromCart={handleRemoveLicenseFromCart}
              handleAddLicenseToCart={handleAddLicenseToCart}
              activeBoonLicenses={activeBoonLicenses}
            />
          </>
        )}

        {/* Platfroms */}
        {licensesTags.includes(RFQ.productTags.platforms.fallbackText) &&
          (productCategory === RFQ.onPremProductCategory.all.fallbackText ||
            productCategory ===
              RFQ.onPremProductCategory.platforms.fallbackText) && (
          <>
            <div
              className="RequestForQuote__BrowseProducts__ProductCategory"
            >
              <p>
                {t(
                  RFQ.onPremProductCategory.platforms.keyText,
                  RFQ.onPremProductCategory.platforms.fallbackText
                )}
              </p>
            </div>

            <Licenses
              windowDimension={windowDimension}
              searchText={searchInputText}
              productCategory={RFQ.onPremProductCategory.platforms}
              productTag={RFQ.productTags.platforms}
              cloudLicenses={licenses}
              handleShowPopUp={handleShowPopUp}
              selectedCategory={selectedCategory}
              lastSelectedOrchestrator={lastSelectedOrchestrator}
              cartProducts={cartProducts}
              isAddToCartAllowed={isAddToCartAllowed}
              requiresAutomationCloudBundle={requiresAutomationCloudBundle}
              requiresAutomationSuiteBundle={requiresAutomationSuiteBundle}
              handleQuantityUpdate={handleQuantityUpdate}
              handleRemoveLicenseFromCart={handleRemoveLicenseFromCart}
              handleAddLicenseToCart={handleAddLicenseToCart}
              activeBoonLicenses={activeBoonLicenses}
            />
          </>
        )}

        {/* Services */}
        {licensesTags.includes(RFQ.productTags.services.fallbackText) &&
          (productCategory === RFQ.onPremProductCategory.all.fallbackText ||
            productCategory ===
              RFQ.onPremProductCategory.services.fallbackText) && (
          <>
            <div
              className="RequestForQuote__BrowseProducts__ProductCategory"
            >
              <p>
                {t(
                  RFQ.onPremProductCategory.services.keyText,
                  RFQ.onPremProductCategory.services.fallbackText
                )}
              </p>
            </div>

            <Licenses
              windowDimension={windowDimension}
              searchText={searchInputText}
              productCategory={RFQ.onPremProductCategory.services}
              productTag={RFQ.productTags.services}
              cloudLicenses={licenses}
              handleShowPopUp={handleShowPopUp}
              selectedCategory={selectedCategory}
              lastSelectedOrchestrator={lastSelectedOrchestrator}
              cartProducts={cartProducts}
              isAddToCartAllowed={isAddToCartAllowed}
              requiresAutomationCloudBundle={requiresAutomationCloudBundle}
              requiresAutomationSuiteBundle={requiresAutomationSuiteBundle}
              handleQuantityUpdate={handleQuantityUpdate}
              handleRemoveLicenseFromCart={handleRemoveLicenseFromCart}
              handleAddLicenseToCart={handleAddLicenseToCart}
              activeBoonLicenses={activeBoonLicenses}
            />
          </>
        )}
      </div>
      {/* End Product List  */}

      {/* Product Full Details */}
      {showPopUp && (
        <LicenseDetailPopUp
          showPopUp={showPopUp}
          popUpData={popUpData}
          handleClosePopUp={handleClosePopUp}
          handleAddLicenseToCart={handleAddLicenseToCart}
          handleQuantityUpdate={handleQuantityUpdate}
          handleRemoveLicenseFromCart={handleRemoveLicenseFromCart}
          cartProducts={cartProducts}
          isAddToCartAllowed={isAddToCartAllowed}
          requiresAutomationCloudBundle={requiresAutomationCloudBundle}
          requiresAutomationSuiteBundle={requiresAutomationSuiteBundle}
          lastSelectedOrchestrator={lastSelectedOrchestrator}
          selectedCategory={selectedCategory}
          activeBoonLicenses={activeBoonLicenses}
        />
      )}
    </styles.OrchestratorProducts>
  );
};

export default RequestForQuoteBrowseLicenseContainer;

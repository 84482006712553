export const COUNTRY_CODE_TO_SUPPORT_GROUP_MAP: { [key: string]: string } = { USA: '00G5b0000036OhMEAU' };

export enum UiPathContactRole {
  ACCOUNT_OWNER = 'Account Owner',
  CSM = 'Customer Success Manager',
  CSD = 'Customer Success Director',
  TAM = 'Technical Account Manager',
  APA = 'Automation Program Advisor',
  ASM = 'Assigned Support Manager',
}

export const ASM_EMAIL = 'asm@uipath.com';

// Status filter for open support/premium cases
export const OPEN_CASE_STATUS_FILTERS = [
  'New',
  'Customer Action',
  'On Hold - Dev',
  'On Hold',
  'In Process',
  'Resolved',
];

// Status filter for closed support/premium cases
export const CLOSED_CASE_STATUS_FILTERS = [ 'Closed' ];

// Status filter for all support/premium cases
export const ALL_CASE_STATUS_FILTERS = OPEN_CASE_STATUS_FILTERS.concat(CLOSED_CASE_STATUS_FILTERS);

export const STATUS_TO_USER_READABLE_MAP: { [key: string]: string } = {
  'On Hold - Dev': 'Engineering Investigating',
  'On Hold': 'Engineering Investigating',
};

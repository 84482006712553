export enum OnPremActions {
  FETCH_INIT = 'FETCH_INIT',
  FETCH_SUCCESS = 'FETCH_SUCCESS',
  FETCH_FAIL = 'FETCH_FAIL',
  PAGE_CLICKED = 'PAGE_CLICKED',
  SORT_UPDATED = 'SORT_UPDATED',
  CANCEL = 'CANCEL',
  FOLDER_CREATED = 'FOLDER_CREATED',
  FOLDER_CLICKED = 'FOLDER_CLICKED',
  KEYWORD_UPDATED = 'KEYWORD_UPDATED',
  ASSET_UPLOADED = 'ASSET_UPLOADED',
  BACK = 'BACK',
  FETCH_FOLDER_SUCCESS = 'FETCH_FOLDER_SUCCESS',
  FILE_DELETED = 'FILE_DELETED',
}

import type {
  CaseAssistEngine,
  CaseAssistEngineConfiguration,
} from '@coveo/headless/case-assist';
import {
  buildCaseAssistEngine,
  buildCaseField,
  loadCaseAssistAnalyticsActions,
} from '@coveo/headless/case-assist';
import {
  Header,
  Locale,
} from '@customer-portal/constants';

import i18n from '../../i18n';
import {
  axiosPost,
  axiosPublicPost,
} from '../client/axios';
import { CaseAssistAnalyticsActions } from '../constants/caseAssist.constants';
import {
  LOG_CUSTOM_COVEO_EVENT,
  PUBLIC_LOG_CUSTOM_COVEO_EVENT_URL,
} from '../constants/network.constants';
import CoveoUtil from './coveo';

export class CPCaseAssistEngine {
  private static instance: CaseAssistEngine;

  public static getInstance(isGuestUser: boolean): CaseAssistEngine {
    if (!CPCaseAssistEngine.instance) {
      CPCaseAssistEngine.instance = headlessCaseAssistEngine({ isGuestUser });
    }
    return CPCaseAssistEngine.instance;
  }
}

export const headlessCaseAssistEngine = (
  { isGuestUser = false }: { isGuestUser?: boolean }
) => {
  let origin: string | undefined;
  let caseAssistId: string | undefined;
  let organizationId: string | undefined;
  try {
    if (
      process.env.REACT_APP_SEARCH_PUBLIC_ORIGIN &&
      process.env.REACT_APP_CASE_ASSIST_ID &&
      process.env.REACT_APP_COVEO_ORGANIZATION_ID
    ) {
      origin = new URL(process.env.REACT_APP_SEARCH_PUBLIC_ORIGIN).origin;
      caseAssistId = process.env.REACT_APP_CASE_ASSIST_ID;
      organizationId = process.env.REACT_APP_COVEO_ORGANIZATION_ID;
    } else {
      throw new Error('Missing environment variable for case assist');
    }
  } catch (e) {
    console.error(e);
  }

  const configuration: CaseAssistEngineConfiguration = {
    caseAssistId: caseAssistId ?? '_',
    organizationId: organizationId ?? '_',
    accessToken: '_',
    platformUrl: origin,
    preprocessRequest: (req, _clientOrigin) => {
      const newHeaders: Headers = new Headers(req.headers);
      // delete authorization header
      newHeaders.delete(Header.AUTHORIZATION);
      req.headers = newHeaders;
      return req;
    },
    searchHub: 'cp-case-assist',
    analytics: {
      analyticsClientMiddleware: (_eventType, payload) => {
        const {
          visitorId, sessionId, isUipathUser,
        } = CoveoUtil.getSessionData();
        payload.visitorId = visitorId;
        payload.custom = {
          ...payload.custom,
          sessionid: sessionId,
          is_internal_user: isUipathUser,
        };
        return payload;
      },
      enabled: true,
      originLevel3: origin,
    },
    locale: i18n.language as Locale,
  };
  const coveoEngine = buildCaseAssistEngine({ configuration });

  // Headeless Case Assist does not support sending custom context so here is the work around that they recommended
  // https://connect.coveo.com/s/case/5006Q000024VQOF/00100473-how-to-pass-custom-context-with-case-assist-engine-using-a-coveo-headless-library?tabset-3b411=2
  const caseUserStateContext = buildCaseField(coveoEngine, { options: { field: 'userState' } });

  caseUserStateContext.update(
    isGuestUser ? 'Visitor' : 'Registered',
    undefined,
    false
  );

  return coveoEngine;
};

export const logCaseAssistAnalyticsEvent = (
  engine: CaseAssistEngine,
  eventType: CaseAssistAnalyticsActions,
  payload?: string,
) => {
  const analyticsAction = loadCaseAssistAnalyticsActions(engine);
  switch (eventType) {
    case CaseAssistAnalyticsActions.CASE_STARTED:
      engine.dispatch(analyticsAction.logCaseStart());
      engine.dispatch(analyticsAction.logCaseNextStage({ stageName: 'Stage 1' }));
      break;
    case CaseAssistAnalyticsActions.CASE_GENERATING_DOCSGPT_RESPONSE:
      engine.dispatch(analyticsAction.logCaseNextStage({ stageName: 'Stage 2 - Generating DocsGPT Response' }));
      break;
    case CaseAssistAnalyticsActions.CASE_DOCSGPT_RESPONSE_TOOK_MORE_THAN_TWENTY_SECONDS:
      engine.dispatch(analyticsAction.logCaseNextStage({ stageName: 'Stage 2 - DocsGPT Response Took More Than Twenty Seconds' }));
      break;
    case CaseAssistAnalyticsActions.CASE_DOCSGPT_RATE_LIMIT_EXCEEDED:
      engine.dispatch(analyticsAction.logCaseNextStage({ stageName: 'Stage 2 - DocsGPT Rate Limit Exceeded' }));
      break;
    case CaseAssistAnalyticsActions.CASE_DOCSGPT_SERVER_ERROR: {
      const errorMessage = payload ? `Stage 2 - DocsGPT Server Error: ${payload}` : 'Stage 2 - DocsGPT Server Error';
      engine.dispatch(analyticsAction.logCaseNextStage({ stageName: errorMessage }));
      break;
    }
    case CaseAssistAnalyticsActions.CASE_COVEO_SUGGESTIONS_EMPTY:
      engine.dispatch(
        analyticsAction.logCaseNextStage({ stageName: 'Stage 2 - Empty Recommendations' })
      );
      break;
    case CaseAssistAnalyticsActions.CASE_COVEO_SUGGESTIONS_SHOWN:
      if (payload) {
        engine.dispatch(
          analyticsAction.logCaseNextStage({ stageName: 'Stage 2 - ' + payload })
        );
      }
      break;
    case CaseAssistAnalyticsActions.CASE_COVEO_SUGGESTION_OPEN:
      if (typeof payload === 'string') {
        engine.dispatch(analyticsAction.logDocumentSuggestionClick(payload));
      }
      break;
    case CaseAssistAnalyticsActions.CASE_DOCSGPT_SUGGESTIONS_SHOWN:
      if (payload) {
        engine.dispatch(
          analyticsAction.logCaseNextStage({ stageName: 'Stage 2 - ' + payload })
        );
      }
      break;
    case CaseAssistAnalyticsActions.CASE_FINAL_PAGE:
      engine.dispatch(analyticsAction.logCaseNextStage({ stageName: 'Stage 3' }));
      break;
    case CaseAssistAnalyticsActions.CASE_CREATED:
      engine.dispatch(analyticsAction.logCreateCase());
      break;
    case CaseAssistAnalyticsActions.CASE_SOLVED:
      engine.dispatch(analyticsAction.logSolveCase());
      break;
    case CaseAssistAnalyticsActions.CASE_ABANDONED:
      engine.dispatch(analyticsAction.logAbandonCase());
      break;
    default:
      break;
  }
};

export const logCustomEvent = (
  eventType: string,
  customData: any,
  companyId: string,
  accessToken: any,
  originLevel1: string,
) => {
  const {
    visitorId: clientId, sessionId, isUipathUser,
  } = CoveoUtil.getSessionData();
  const language = i18n.language as Locale ?? Locale.en;
  const customDataWithSession = {
    ...customData,
    sessionid: sessionId,
    is_internal_user: isUipathUser,
    context_source: 'cp',
    // Creating another custom userState API for logging custom events (custom_events.c_userstate)
    // This is different userState API from Case Assist (c_origional_event_data_svc_ticket_custom_userstate) as it is custom for Case Assist
    // More information here: https://connect.coveo.com/s/case/500VO00000RjizqYAB/00121237-how-to-pass-in-custom-field-defined-as-semistructured-dimension-in-logging-custom-event
    userstate: 'Registered',
  };

  axiosPost(LOG_CUSTOM_COVEO_EVENT,
    companyId,
    accessToken,
    {
      eventType,
      eventValue: 'n/a',
      language,
      clientId,
      customData: customDataWithSession,
      originLevel1,
      originLevel3: window.location.origin,
    });
};

export const logPublicCustomEvent = (
  eventType: string,
  customData: any,
  originLevel1: string,
) => {
  const {
    visitorId: clientId, sessionId, isUipathUser,
  } = CoveoUtil.getSessionData();
  const language = i18n.language as Locale ?? Locale.en;
  const customDataWithSession = {
    ...customData,
    sessionid: sessionId,
    is_internal_user: isUipathUser,
    context_source: 'cp',
    userstate: 'Visitor',
  };

  axiosPublicPost(PUBLIC_LOG_CUSTOM_COVEO_EVENT_URL,
    {
      eventType,
      eventValue: 'n/a',
      language,
      clientId,
      customData: customDataWithSession,
      originLevel1,
      originLevel3: window.location.origin,
    });
};

export const AvatarWhiteListedFileExtensions = [
  '.gif',
  '.jpeg',
  '.jpg',
  '.png',
];

export const Questionnaire360WhiteListedFileExtensions = [
  '.csv',
  '.zip',
  '.xls',
  '.xlsx',
  '.xlsm',
];

export const SupportWhiteListedFileExtensions = [
  ...AvatarWhiteListedFileExtensions,
  '.bmp',
  '.zip',
  '.7zip',
  '.7z',
  '.bz',
  '.bz2',
  '.gz',
  '.rar',
  '.txt',
  '.csv',
  '.mp3',
  '.oga',
  '.mov',
  '.qt',
  '.mpeg',
  '.mp4',
  '.rtf',
  '.pdf',
  '.doc',
  '.docx',
  '.dot',
  '.pps',
  '.ppt',
  '.pptx',
  '.pot',
  '.xls',
  '.xlsx',
  '.xlsm',
  '.xlt',
];

export const CollabWhiteListedFileExtensions = [
  ...SupportWhiteListedFileExtensions,
  '.3g2',
  '.3gp',
  '.avi',
  '.h261',
  '.h263',
  '.h264',
  '.ico',
  '.m4v',
  '.mp4',
  '.mp4v',
  '.mpg4',
  '.mp4a',
  '.mpg',
  '.mpga',
  '.mpp',
  '.mpt',
  '.odp',
  '.ods',
  '.odt',
  '.ogg',
  '.ogv',
  '.onetoc',
  '.onetoc2',
  '.onepkg',
  '.onetmp',
  '.potm',
  '.potx',
  '.ppam',
  '.ppm',
  '.ppsm',
  '.ppsx',
  '.pwz',
  '.ppa',
  '.pptm',
  '.rtx',
  '.sldm',
  '.sldx',
  '.text',
  '.vsd',
  '.vss',
  '.vst',
  '.vsw',
  '.vsw',
  '.p7c',
  '.p7m',
  '.wav',
  '.weba',
  '.webm',
  '.wm',
  '.wma',
  '.wmv',
  '.wri',
  '.x3d',
  '.xlam',
  '.xla',
  '.xlb',
  '.xlc',
  '.xlm',
  '.xlw',
  '.xlsb',
  '.xlsm',
  '.xltm',
  '.xltx',
  '.yaml',
];

export const KBWhiteListedFileExtensions = [
  ...CollabWhiteListedFileExtensions,
];

export const SupportEmailReplyWhiteListedFileExtensions = [
  '.txt',
  '.csv',
  '.rtf',
  '.pdf',
  '.doc',
  '.docx',
  '.ppt',
  '.pptx',
  '.xls',
  '.xlsx',
  '.xml',
  '.bmp',
  '.gif',
  '.jpeg',
  '.jpg',
  '.png',
  '.zip',
  '.rar',
  '.7zip',
  '.mp3',
  '.ogg',
  '.mpeg',
  '.mpg',
  '.mov',
];

export const SupportLargeFileAttachmentWhiteListedFileExtensions = [
  '.png',
  '.gif',
  '.jpeg',
  '.jpg',
  '.log',
  '.txt',
  '.doc',
  '.docx',
  '.pdf',
  '.xls',
  '.xlsx',
  '.csv',
  '.zip',
  '.config',
  '.evtx',
  '.xaml',
  '.tar',
  '.gzip',
  '.har',
  '.bz2',
  '.gz',
  '.7z',
  '.nupkg',
  '.saz',
  '.json',
];

export const OnPremWhiteListedFileExtensions = [
  '.xml',
];

import { SupportLargeFileAttachmentWhiteListedFileExtensions } from '@customer-portal/constants';
import {
  Button,
  CircularProgress,
  FormControl,
} from '@mui/material';
import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CustomActionPanel } from '../../../assets/css/Support/CaseActionPanel';
import { axiosPost } from '../../../client/axios';
import { ActionType } from '../../../constants/caseActions.constants';
import {
  CREATE_ATTACHMENT_RECORD_URL,
  EMAIL_URL,
} from '../../../constants/network.constants';
import { useAuth } from '../../../contexts/auth';
import { encodeUTF8ToBase64 } from '../../../lib/encodings.utils';
import { StoreContext } from '../../../store';
import FileUpload from '../../FileUpload/FileUpload';
import TextAreaWithCharCount from '../../TextAreaWithCharCount';
import TextLabel from '../../TextLabel';

export type AttachLogFilesProps = {
  id: string;
  caseNumber: string;
  setActionType: (value: string) => void;
};

export type AttachLogFilesData = {
  description: string;
  files: File[];
};

const AttachLogFiles = (props: AttachLogFilesProps) => {
  const {
    id, caseNumber, setActionType,
  } = props;
  const baseClass = 'CaseActions';
  const { t } = useTranslation('common');
  const {
    state, dispatch,
  } = useContext(StoreContext);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
  } = useForm<AttachLogFilesData>({
    mode: 'onChange',
    defaultValues: {
      description: '',
      files: [],
    },
  });

  const description = watch('description');
  const files = watch('files');

  const { accessToken } = useAuth();
  const [ loading, setLoading ] = useState(false);
  const [ attachLogLoading, setAttachLogLoading ] = useState(false);
  const [ clickedSubmit, setClickedSubmit ] = useState(false);
  const [ acceptedFiles, setAcceptedFiles ] = useState<File[]>([]);
  const [ uploadedFiles, setUploadedFiles ] = useState<any[]>([]);
  const [ allFilesUploaded, setAllFilesUploaded ] = useState(false);

  useEffect(() => {
    if (allFilesUploaded) {
      createSFDCRecord();
    }
  }, [ allFilesUploaded ]);

  const createSFDCRecord = async () => {
    try {
      const formData = new FormData();
      const uploadedFilesList = uploadedFiles.map(file => `<p>- ${file.fileName}: ${file.fileUrl}</p>`).join('');
      formData.set(
        'textBody',
        encodeUTF8ToBase64('Attach Logs Detail: ' + description + '<p><br></p><p>{Attached Files}</p>' + uploadedFilesList)
      );
      formData.set('caseId', id);
      formData.set('subject', '<No Subject>');

      const result = await axiosPost(
        `${EMAIL_URL}/sendSupportEmail`,
        state.companyId,
        accessToken,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );

      if (result.status === 200) {
        const attachmentData = {
          uploadedFiles,
          'caseId': id,
          'emailMessageId': result.data.emailId,
          'attachmentType': 'Log File',
        };

        const recordRes = await axiosPost(
          `${CREATE_ATTACHMENT_RECORD_URL}`,
          state.companyId,
          accessToken,
          attachmentData
        );

        if (recordRes.status === 201) {
          dispatch({
            type: 'toggleActionPanel',
            payload: false,
          });
          setActionType(ActionType.None);
          dispatch({
            type: 'setBannerType',
            payload: 'success',
          });
          dispatch({
            type: 'setBannerMsg',
            payload: t(
              'support_incident_cases_attach_log_files_success',
              `Files successfully attached to case ${caseNumber}.`,
              { caseNumber }
            ),
          });
          setLoading(false);
          window.location.reload();
        }
      }
    } catch (err) {
      dispatch({
        type: 'setBannerType',
        payload: 'error',
      });
      dispatch({
        type: 'setBannerMsg',
        payload: t(
          'support_incident_cases_attach_log_files_fail',
          'We\'re sorry, uploading of the log file failed. Please try again or update the case.'
        ),
      });
      setLoading(false);
      setClickedSubmit(false);
      console.error('Something went wrong while uploading the log file!', err);
    }
  };

  const onSubmit = async () => {
    setLoading(true);
    const descriptionInput = description.trim();
    if (descriptionInput.length < 3) {
      dispatch({
        type: 'setBannerType',
        payload: 'error',
      });
      dispatch({
        type: 'setBannerMsg',
        payload: t(
          'support_incident_case_action_invalid_input',
          'Invalid input. Please provide a valid input with at least 3 printable characters.'
        ),
      });
      setLoading(false);
    } else {
      dispatch({
        type: 'setBannerType',
        payload: 'info',
      });
      dispatch({
        type: 'setBannerMsg',
        payload: t(
          'support_incident_cases_attach_log_files_info_msg',
          'Attaching log files to case. This may take a few minutes...'
        ),
      });
      setClickedSubmit(true);
    }
  };

  const onCancel = () => {
    reset();
    dispatch({
      type: 'toggleActionPanel',
      payload: false,
    });
    setActionType(ActionType.None);
  };

  return (
    <CustomActionPanel data-testid="AttachLogFilesSupport">
      <div className='form-container'>
        <p className='title'>
          {t(
            'support_incident_cases_attach_log_files_case_number',
            `Attach Logs #${caseNumber}`,
            { caseNumber }
          )}
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl className={`${baseClass}__Case-Field businessJustification`}>
            <TextLabel
              label={t('support_incident_cases_attach_log_files_description', 'Description of attachment')}
              required
            />
            <TextAreaWithCharCount
              value={description}
              placeholder={t('support_incident_cases_attach_log_files_description_placeholder', 'Add information regarding the file you are attaching...')}
              register={{ ...register('description') }}
              disabled={loading}
              aria-label="empty textarea"
              rowsMin={10}
              maxLength={1000}
              label="description"
            />
          </FormControl>
          <FormControl className={`${baseClass}__Case-Field businessJustification`}>
            <TextLabel
              label={t('support_incident_cases_attach_log_files_add_files', 'Add files here')}
              required
            />
            <FileUpload
              caseId={id}
              maxFiles={5}
              supportedFiles={SupportLargeFileAttachmentWhiteListedFileExtensions}
              setValue={setValue}
              clickedSubmit={clickedSubmit}
              setClickedSubmit={setClickedSubmit}
              acceptedFiles={acceptedFiles}
              setAcceptedFiles={setAcceptedFiles}
              uploadedFiles={uploadedFiles}
              setUploadedFiles={setUploadedFiles}
              setAllFilesUploaded={setAllFilesUploaded}
              setAttachLogLoading={setLoading}
            />
          </FormControl>
        </form>
      </div>
      <div className='button-container'>
        <div className='button'>
          <Button
            variant='outlined'
            onClick={onCancel}
            disabled={loading}
            className='cancelBtn'
          >
            {t('support_incident_cases_cancel', 'Cancel')}
          </Button>
          {
            !loading ? (
              <Button
                variant='contained'
                color='secondary'
                onClick={handleSubmit(onSubmit)}
                disabled={!description || files.length === 0}
                className='submitBtn'
                data-testid='submit-button'
              >
                {t('support_incident_cases_submit', 'Submit')}
              </Button>
            ) : (
              <Button
                variant='contained'
                color='secondary'
                className='loaderBtn'
              >
                <CircularProgress
                  size={24}
                  className='loader'
                />
              </Button>
            )
          }
        </div>
      </div>
    </CustomActionPanel>
  );
};

export default AttachLogFiles;

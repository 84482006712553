import {
  FileSizes,
  SupportLargeFileAttachmentWhiteListedFileExtensions,
} from '@customer-portal/constants';
import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import { useDropzone } from 'react-dropzone';
import type { UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CustomFileUpload } from '../../assets/css/FileUpload';
import { isFileAttachmentSupported } from '../../lib/file.utils';
import { StoreContext } from '../../store';
import type { AttachLogFilesData } from '../support/CaseActions/AttachLogFiles';
import ApolloIcon from './../ApolloIcon';
import FileUploadList from './FileUploadList';

export type FileUploadProps = {
  caseId?: string;
  maxFiles: number;
  maxSize?: number;
  supportedFiles: string[];
  setValue?: UseFormSetValue<AttachLogFilesData>;
  clickedSubmit: boolean;
  setClickedSubmit: (value: boolean) => void;
  acceptedFiles: File[];
  setAcceptedFiles: (value: File[]) => void;
  uploadedFiles: any[];
  setUploadedFiles: (value: any[]) => void;
  setAllFilesUploaded: (value: boolean) => void;
  setAttachLogLoading: (value: boolean) => void;
  isPublic?: boolean;
  resetFileUploadStates?: boolean;
  setResetFileUploadStates?: (value: boolean) => void;
};

const FileUpload = (props: FileUploadProps) => {
  const {
    caseId,
    maxFiles,
    supportedFiles,
    setValue,
    clickedSubmit,
    setClickedSubmit,
    acceptedFiles,
    setAcceptedFiles,
    uploadedFiles,
    setUploadedFiles,
    setAllFilesUploaded,
    setAttachLogLoading,
    isPublic,
    resetFileUploadStates,
    setResetFileUploadStates,
  } = props;
  const supportedFilesFormatted = supportedFiles.map(c => c.replace('.', '')).join(', ');
  const [ failedUploadFiles, setFailedUploadFiles ] = useState<File[]>([]);
  const [ fileSizeErrorMessage, setFileSizeErrorMessage ] = useState(null);
  const [ invalidTypeErrorMessage, setInvalidTypeErrorMessage ] = useState(null);
  const [ duplicateFilesErrorMessage, setDuplicateFilesErrorMessage ] = useState(null);
  const baseClass = 'FileUpload';
  const { t } = useTranslation('common');
  const { dispatch } = useContext(StoreContext);
  const {
    getRootProps, getInputProps,
  } = useDropzone({
    onDrop: (files: File[]) => {
      setFileSizeErrorMessage(null);
      setInvalidTypeErrorMessage(null);
      setDuplicateFilesErrorMessage(null);
      if (acceptedFiles.length + files.length > maxFiles) {
        dispatch({
          type: 'setBannerType',
          payload: 'error',
        });
        dispatch({
          type: 'setBannerMsg',
          payload: t(
            'support_form_attachment_max_file_error',
            'You are only allowed to upload up to {{maxFiles}} files at a time',
            { maxFiles }
          ),
        });
        return;
      }

      const acceptedFilesList: File[] = [];
      const exceedSizeFiles: String[] = [];
      const invalidTypeFiles: String[] = [];
      const duplicateFiles: String[] = [];
      files.forEach(file => {
        if (acceptedFiles.some(uploadedFile => file.name === uploadedFile.name)) {
          duplicateFiles.push(file.name);
        } else if (file && file.size > FileSizes.OneGigabyte) {
          exceedSizeFiles.push(file.name);
        } else if (!isFileAttachmentSupported(file, SupportLargeFileAttachmentWhiteListedFileExtensions)) {
          invalidTypeFiles.push(file.name);
        } else {
          acceptedFilesList.push(file);
        }
      });

      setAcceptedFiles([ ...acceptedFiles, ...acceptedFilesList ]);
      setValue && setValue('files', [ ...acceptedFiles, ...acceptedFilesList ]);
      if (duplicateFiles.length > 0) {
        const duplicateFilesFormatted = duplicateFiles.join(', ');
        setDuplicateFilesErrorMessage(t('support_form_attachment_duplicate_file_error',
          'File with same name already uploaded: {{duplicateFilesFormatted}}',
          { duplicateFilesFormatted }));
      }
      if (exceedSizeFiles.length > 0) {
        const exceedSizeFilesFormatted = exceedSizeFiles.join(', ');
        setFileSizeErrorMessage(t('support_form_attachment_file_size_error',
          'File exceeds 1GB: {{exceedSizeFilesFormatted}}',
          { exceedSizeFilesFormatted }));
      }
      if (invalidTypeFiles.length > 0) {
        const invalidTypeFilesFormatted = invalidTypeFiles.join(', ');
        setInvalidTypeErrorMessage(t('support_form_attachment_invalid_type_error',
          'Invalid file type: {{invalidTypeFilesFormatted}}',
          { invalidTypeFilesFormatted }));
      }
    },
  });

  useEffect(() => {
    if (acceptedFiles.length > uploadedFiles.length) {
      setAllFilesUploaded(false);
    }
  }, [ acceptedFiles ]);

  useEffect(() => {
    if (resetFileUploadStates) {
      setFileSizeErrorMessage(null);
      setInvalidTypeErrorMessage(null);
      setDuplicateFilesErrorMessage(null);
      setResetFileUploadStates?.(false);
    }
  }, [ resetFileUploadStates ]);

  useEffect(() => {
    const uploadFileCount = uploadedFiles.length + failedUploadFiles.length;
    if (uploadFileCount > 0 && uploadFileCount === acceptedFiles.length) {
      if (uploadedFiles.length === acceptedFiles.length) {
        setAllFilesUploaded(true);
        setClickedSubmit(false);
      } else {
        setAttachLogLoading(false);
        setClickedSubmit(false);
        setFailedUploadFiles([]);
        dispatch({
          type: 'setBannerType',
          payload: 'error',
        });
        dispatch({
          type: 'setBannerMsg',
          payload: t(
            'support_incident_cases_attach_log_files_fail',
            'We\'re sorry, uploading of the log file failed. Please try again or update the case.'
          ),
        });
      }
    }
  }, [ uploadedFiles, failedUploadFiles, acceptedFiles ]);

  return (
    <CustomFileUpload>
      <div
        className={`${baseClass}_Box`}
        {...getRootProps()}>
        <input
          data-testid='file-upload-box'
          disabled={clickedSubmit}
          {...getInputProps()} />
        <div className={`${baseClass}_Placeholder`}>
          <p>{t('support_form_attachment_placeholder', 'Drop files to upload or click to browse')}</p>
          <ApolloIcon
            icon='add_box'
            fontSize='large'
            className={`${baseClass}_Placeholder-Icon`}
            outlined
          />
        </div>
      </div>
      <p className={`${baseClass}_Note`}>
        {t('support_form_attachment_note',
          'Up to {{maxFiles}} files (max. 1GB each). Supported file types: {{supportedFilesFormatted}}', {
            maxFiles,
            supportedFilesFormatted,
          })}
      </p>

      {duplicateFilesErrorMessage && (<p className={`${baseClass}_Error`}>{duplicateFilesErrorMessage}</p>)}
      {fileSizeErrorMessage && (<p className={`${baseClass}_Error`}>{fileSizeErrorMessage}</p>)}
      {invalidTypeErrorMessage && (<p className={`${baseClass}_Error`}>{invalidTypeErrorMessage}</p>)}

      <div className={`${baseClass}_Files`}>
        {acceptedFiles.map((file: File) => (
          <FileUploadList
            caseId={caseId}
            key={file.name}
            file={file}
            acceptedFiles={acceptedFiles}
            setAcceptedFiles={setAcceptedFiles}
            uploadedFiles={uploadedFiles}
            setUploadedFiles={setUploadedFiles}
            failedUploadFiles={failedUploadFiles}
            setFailedUploadFiles={setFailedUploadFiles}
            setValue={setValue}
            clickedSubmit={clickedSubmit}
            isPublic={isPublic} />
        ))}
      </div>
    </CustomFileUpload>
  );
};
export default FileUpload;

import { CompanyAccountType } from '@customer-portal/constants';
import React, {
  useContext,
  useState,
} from 'react';
// Translations
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';

// Styles
import * as styles from '../../assets/css/Support/CustomerPortalSupportHeader';
// Constants
import { UserPermissionsHelper } from '../../lib/UserPermissions';
import { StoreContext } from '../../store';
// Components
import Container from '../CustomerPortal-New-Container';
// Assets
import defaultHeroBg from './../../assets/img/Hero-Header.png';
import ApolloIcon from './../ApolloIcon';
import CustomerPortalSupportButton from './CustomerPortal-SupportButton';
import PremiumSupportContactModal from './PremiumSupport-Contact-Modal';

type BasicHeroProps = {
  cssClass?: string;
  heroBg?: string;
  title: string;
  subtitle?: string;
  backButton?: boolean;
  backButtonHref?: string;
  showStickerText?: boolean;
};

const CustomerPortalSupportHeader = (props: BasicHeroProps) => {
  // Translate method
  const { t } = useTranslation('common');
  const { state } = useContext(StoreContext);
  const cssClass = props.cssClass ? props.cssClass : '';
  const title = props.title ? props.title : 'Hero title...';
  const subtitle = props.subtitle ? props.subtitle : 'Hero subtitle...';
  const heroBg = props.heroBg ? props.heroBg : defaultHeroBg;
  const backButton = props.backButton ? props.backButton : false;
  const backButtonHref = props.backButtonHref ? props.backButtonHref : '';
  const [ back, setBack ] = useState(false);
  const [ supportModalOpen, setSupportModalOpen ] = useState(false);
  const showPremiumContactButton =
    UserPermissionsHelper.isEditPremiumSupportAllowed() ||
    UserPermissionsHelper.isSupportUser();
  const isPremiumAccount = UserPermissionsHelper.isPremiumAccount();

  const toggleSupportModal = () => {
    setSupportModalOpen(!supportModalOpen);
  };

  const getStickerText = () => {
    switch (state.companyType) {
      case CompanyAccountType.ACTIVATE:
        return t('support_activate_care_hero_logo_text');
      case CompanyAccountType.ENTERPRISE:
        return t('support_enterprise_care_hero_logo_text');
      default:
        return t('support_premium_support_cases_hero_logo_text');
    }
  };

  return (
    <>
      <PremiumSupportContactModal
        open={supportModalOpen}
        onClose={toggleSupportModal}
      />
      <styles.Hero
        className={cssClass}
        heroBg={heroBg}>
        {back && <Redirect
          push
          to={backButtonHref} />}
        <styles.PremiumWrapper>
          <Container cssClass="CustomerPortalClientsSelection__heroContent">
            <div>
              {backButton && (
                <div
                  className="heroBackButton"
                  onClick={() => {
                    setBack(true);
                  }}
                >
                  Go Back
                </div>
              )}
              <styles.Title>
                {title}
                {props.showStickerText && (
                  <styles.PremiumStickerWrapper>
                    {getStickerText()}
                  </styles.PremiumStickerWrapper>
                )}
              </styles.Title>
              {props.subtitle && (
                <styles.PremiumSubtitle>{subtitle}</styles.PremiumSubtitle>
              )}
            </div>
            <div>
              { isPremiumAccount && (
                <CustomerPortalSupportButton
                  disabled={!showPremiumContactButton}
                  onClick={toggleSupportModal}
                >
                  <ApolloIcon
                    icon="phone"
                    fontSize='inherit'
                    className='icon'
                  />
                  {t(
                    'support_premium_support_cases_hero_premium_support_btn',
                    'Support Phone Numbers'
                  )}
                </CustomerPortalSupportButton>
              )}
            </div>
          </Container>
        </styles.PremiumWrapper>
      </styles.Hero>
    </>
  );
};

export default CustomerPortalSupportHeader;

/* Statuses from DB */
export const PENDING = 'pending';
export const PROVISIONED = 'provisioned';
export const CANCELED = 'cancelled';

// HAPO orders statuses to display
export const DISPLAY_STATUSES = {
  pending: 'In Progress',
  provisioned: 'Provisioned',
  canceled: 'Cancelled',
};

/* DealType variable coming from BE */
export const DEAL_TYPE_ELA = 'ela';
export const DEAL_TYPE_HAPO = 'hapo';
export const DEAL_TYPE_UTO = 'uto';

/* Group names */
export const NONPRODUCTION = 'Non-Production';
export const AI_ROBOT = 'AI Robot';
export const USERS = 'Users';

/* CSV */
export const CSV_CHANGE_HISTORY_COLUMNS = 10;

/* Config Focused Constants */
export const INDIVIDUAL = 'individual';
export const GROUP = 'group';
export const PM_DEVELOPER = 'pm developer';

export const UNLIMITED_NUM = 999999;
export const UNLIMITED = {
  keyText: 'rfq_hapo_browse_products_unlimited_quantity_text',
  fallbackText: 'Unlimited',
};

export const LICENSE_VERSIONS = [
  {
    label: '2024.4',
    value: '2024.4',
  },
  {
    label: '2023.10',
    value: '2023.10',
  },
  {
    label: '2023.4',
    value: '2023.4',
  },
  {
    label: '2022.10',
    value: '2022.10',
  },
  {
    label: '2022.4',
    value: '2022.4',
  },
  {
    label: '2021.10',
    value: '2021.10',
  },
  {
    label: '2021.4',
    value: '2021.4',
  },
  {
    label: '2020.10',
    value: '2020.10',
  },
];

export const CANCEL_ORDER_TEXT =
  'Are you sure you want to cancel the request. It will take up to 4 hours to complete the process.';

// Custom group names
export const DEDICATED_AUTOMATION_CLOUD = 'Automation Cloud - Dedicated';
const USER_LICENSES = 'User Licenses';
const UNATTENDED_ROBOT = 'Unattended Robots';
const UNATTENDED_ROBOT_TEST = 'Unattended Robot - Test';
const UNATTENDED_NONPRODUCTION_ROBOT = 'Unattended Robots - Non-Production';
const ML_MODEL_INSTANCES = 'ML Model Instances (1 AI Robot = 2 ML Instances)';
const DOCUMENT_UNDERSTANDING = 'Document Understanding (pages)';
const INTEGRATION_SERVICES = 'Integration Service API Calls Bundles';
const CLOUD_PROCESS_MINING_BANDWIDTH = 'Process Mining Rows Bundles';
const PLATFORM_CAPABILITIES = 'Platform Capabilities';
const AI_UNIT_BUNDLES = 'AI Units Bundles';
const ROBOT_UNIT_BUNDLE = 'Robot Units Bundles';

export const getELAGroups = () => [
  USER_LICENSES,
  UNATTENDED_ROBOT,
  UNATTENDED_ROBOT_TEST,
  UNATTENDED_NONPRODUCTION_ROBOT,
  ML_MODEL_INSTANCES,
  DOCUMENT_UNDERSTANDING,
  INTEGRATION_SERVICES,
  CLOUD_PROCESS_MINING_BANDWIDTH,
  PLATFORM_CAPABILITIES,
  AI_UNIT_BUNDLES,
  ROBOT_UNIT_BUNDLE,
  DEDICATED_AUTOMATION_CLOUD,
];

export const VERSION_PATTERN_REGEX = /^(\d{2}|\d{4})\.\d+(\.\d+)*$/;

// Special Product Codes
export const FLEX_AI_UNITS_BUNDLE_60K = 'UIFDAIUB600'; // Flex - AI Unit Bundle - 60K

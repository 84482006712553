export enum Env {
  Localhost = 'localhost',
  Development = 'development',
  Staging = 'staging',
  Production = 'production',
}

export enum EnvShort {
  Local = 'local',
  Dev = 'dev',
  Stg = 'stg',
  Prod = 'prod',
}

export const ENV_TO_ENV_SHORT: Record<Env, EnvShort> = {
  [Env.Localhost]: EnvShort.Local,
  [Env.Development]: EnvShort.Dev,
  [Env.Staging]: EnvShort.Stg,
  [Env.Production]: EnvShort.Prod,
};

export const SEVEN_DAYS_IN_SECONDS = 7 * 24 * 60 * 60;
export const TWENTY_SECONDS_IN_MS = 20000;
